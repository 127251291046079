import React, { useState, useContext, useEffect } from 'react'
import DictionaryContext from '../../../context/dictionaryContext'
import UserPrefContext from '../../../context/userPrefContext'
import Form from 'react-bootstrap/Form'
import { FormControl, FormGroup, FormLabel,Row, Col } from 'react-bootstrap'

function BotoxType2(props) {
    const {dict_botox_map} = useContext(DictionaryContext)



    // create array for botox type dropdown with elements id and label (remove all duplicates too)
    const array_id_label = dict_botox_map.map(bm => {return {id: bm.DictBotoxMap.id, label: bm.DictBotoxMap.dictionary_detail}})
    // console.log("botox types one line code - array_id_label", array_id_label)
    // remove duplicates
    const botoxTypes = [... new Set(array_id_label.map(a => a.id))].map(id => array_id_label.find(a => a.id === id))
    // console.log("botox types one line code", botoxTypes)

    let valueSelected = "";

    if (props.sendEditBotoxMapData.botox_map_type != null) {
        // This is to set the default value based on whether it is edit or new canvas
        valueSelected = props.sendEditBotoxMapData.botox_map_type;
    } else {
        valueSelected = "Botox A"; // default
    }

    let defaultBotoxType = botoxTypes.find(bt => bt.label === valueSelected).id

    //The botox pointer needs to be set to whatever the selectedvalue is
    const [botoxPointer, setBotoxPointer] = useState(defaultBotoxType);

    useEffect(() => {
        props.sendBotoxPointerToParent(botoxPointer);   
    }, [botoxPointer]);


    

    const onSelect = (e) => {
        props.setColorValueSource('default')
        // setBotoxPointer(e.target.value);
        defaultBotoxType = e.target.value
        props.sendBotoxPointerToParent(e.target.value);
    }

    return (
        <>
            <FormGroup as={Row} className="d-flex align-items-center">
                <FormLabel column lg={5} sm={5} /*className='col-form-label col-lg-4 col-md-4 my-0'*/>Botox Type:</FormLabel>

                {/* <div className='col-lg-6 my-1'> */}
                <Col sm={7} md={6} lg={6}>
                    <Form.Select
                        name='botox_type_dropdown' 
                        size='sm'
                        onChange={onSelect}
                        defaultValue={defaultBotoxType}
                        id="botox-type-select"
                    >
                         {botoxTypes.map(bt => 
                        <option 
                                key={bt.id}
                                value={bt.id}
                            >
                                {bt.label}
                            </option>
                        )}
                    </Form.Select>
                </Col>
                {/* </div> */}
                

            </FormGroup>
        </>
    )
}

export default BotoxType2
