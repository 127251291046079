import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BsFillPlusSquareFill, BsPencilSquare, BsTrash3 } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import BotoxMap from '../fabric/BotoxMap';
import PromptBotoxMapDelete from './PromptBotoxMapDelete';
import ColorBox from '../colorboxes/ColorBox';
import BotoxType from '../dropdowns/BotoxType';
import BotoxMapImage from '../dropdowns/BotoxMapImage';
import ErrorDialogModal from './ErrorDialogModal';
import styles from '../../stylesheets/VisualTreatmentModal.module.css'
import userPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext';
import CompanyContext from '../../../context/companyContext';

import VisualTreatmentModal2 from './VisualTreatmentModal2';
// import VisitWithPrefContext from '../../../context/visitWithPrefContext';

let DEFAULT_IMAGE = "";
//const publicImage = "botoxmap.png";
const publicImage = "botox-map-full.png";

function VisualTreatmentModal(props) {

  const userPrefCtx = useContext(userPrefContext);
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext);
//   const visitWithPrefCtx = useContext(VisitWithPrefContext);
  // console.log('userPrefCtx is ', userPrefCtx)
  // console.log('userPrefCtx.dictBotoxMapSrcImage is ', userPrefCtx.dictBotoxMapSrcImage)

  const defaultImageFromDict = userPrefCtx.dictBotoxMapSrcImage?.file_name;
  //console.log(defaultImageFromDict)

  if(defaultImageFromDict === null || defaultImageFromDict === undefined){
    DEFAULT_IMAGE = publicImage;
    //console.log('defaultImageFromDict is null')
  } else {
    // DEFAULT_IMAGE = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${defaultImageFromDict}`
    DEFAULT_IMAGE = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${defaultImageFromDict}?dict_company_code_id=${companyCtx.company.id}`
  }


  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState(false);
  const [editData, setEditData] = useState([]);
  const [openDeleteBotoxModal, setOpenDeleteBotoxModal] = useState(false);
  const [botoxTypeSelect, setBotoxTypeSelect] = useState();
  const [botoxImageSelect, setBotoxImageSelect] = useState(DEFAULT_IMAGE);
  const [inputList, setInputList] = useState([]);
  const [noteState, setNoteState] = useState();
  const [colorValueSource, setColorValueSource] = useState('data');
  const [isChecked, setIsChecked] = useState(false);
  const [isColorScaleIncluded, setIsColorScaleIncluded] = useState(false)
  const [startDate, setStartDate] = useState();

  const noteRef = useRef(null);

  // useEffect(() => {

  // }, [botoxTypeStringName])

  
  useEffect(() => {
    if(userPrefCtx?.userPreference?.check_pat_name_botox === false){
      //console.log(userPrefCtx?.userPreference?.check_pat_name_botox)
      //setIsChecked(userPrefCtx?.userPreference?.check_pat_name_botox);
    } else{
      //console.log(userPrefCtx?.userPreference?.check_pat_name_botox)
      //setIsChecked(userPrefCtx?.userPreference?.check_pat_name_botox);
      setIsChecked(true);
    }

    setIsColorScaleIncluded(!!userPrefCtx.userPreference.check_colors_botox)

  }, [userPrefCtx.userPreference])

  const handleClose = () => { 
    setShow(false); 
    //This is to clear the selection in the visit component through callbacks, send math.random so that it send a different value so that theres a change in props every time
    props.deleteTrigger(Math.random());
  }
  const handleShow = () => {
    setColorValueSource('default');
    //setting the id to null when the new botox button is clicked
    setEditData([null]);
    //setEditData(null);
    setShow(true);
  }

  const childRef = useRef();

  const handleEditShow = async (setShowFunc) => { 
    //sending the edit when the edit botox button is clicked
    setColorValueSource('data');
    //console.log(props.sendSelectedBotoxIds)
    //console.log(props.sendSelectedBotoxIds.length)

    // If a button is selected for editing, then it will call the api, otherwise it will send an alert to select an image
      if (props.sendSelectedBotoxIds.length === 1) {

      const url = process.env.REACT_APP_SERVER + `/api/v1/botox_maps/` + props.sendSelectedBotoxIds[0];
      const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode,};

      try {
        const response = await fetch(url, { headers });
        if (response.status === 200) {
          const data = await response.json();
          setEditData(data[0])
          console.log('edit response in VisualTreatmentModal',data)
          setShowFunc(true); 
        } else {
          console.log("not 200");
          console.log(props.sendBotoxObjects)
          if(props.sendBotoxObjects[0]['BotoxMapJsonImage']?.botox_map_image_data != null){
            //If the BotoxMapImage is null, it would fail to fetch the edit data from above but would instead use json data to load the botox map from the BotoxMapJsonImage object of that image
            setEditData(props.sendBotoxObjects[0]['BotoxMapJsonImage'])
            //console.log('edit response in VisualTreatmentModal',data)
            setShowFunc(true); 
          }
        }
      } catch (e) {
        // console.log(e);
      }
    }
    else{
      //alert('A Maximum Of 1 Images Can Be Selected')
      setErrorDialogMessage('A Maximum Of 1 Images Can Be Selected')
      //This is to show that there's more than 1 image in the error dialog modal
      setShowError(true);
    }
  
  }
  
  const getBotoxImageID = (data) => {
    //This is the function to get the color value from the BotoxType(child) component
    //console.log('this is the id in the modal', data);
    props.getModal(data)
    //testing
    //setShow(false);
  }

  function handleClickChild() {
    childRef.current.childFunction();
    //console.log(childRef.current.childFunction())
    //This is to close the modal once the save function from the botoxmap is run
    handleClose();
  }
  function handleClickChildBotoxImage() {
    childRef.current.childFunctionBotoxImage();
    //This is to close the modal once the save function from the botoxmap is run
    //handleClose();
  }

  const closeDeleteModal = (data) => {
    setOpenDeleteBotoxModal(false)
    //this is to close the previous modal when a diagnosis is deleted
    //props.close();
  }

  const openDeleteModal = (data) => {
    //console.log('props.sendSelectedBotoxIds is ', props.sendSelectedBotoxIds)
    //console.log('props.sendSelectedBotoxIds.length is ', props.sendSelectedBotoxIds.length)
    if(props.sendSelectedBotoxIds.length === 1){
      setOpenDeleteBotoxModal(true)
    } else {
        setErrorDialogMessage('A Maximum Of 1 Images Can Be Selected')
    //   alert('A Maximum Of 1 Images Can Be Selected');
    setShowError(true);
    }
    //this is to close the previous modal when a diagnosis is deleted
    //props.close();
  }

  const sendDeletetoParent = (data) => {
    //This is to send the delete response back to the parent modal to trigger the panel
    props.deleteTrigger(data);
  }

  const pullDataFromBotoxType = (data) => {
    //This is the function to get the color value from the BotoxType(child) component
    //This will need to be sent to the the dropdown/colorpicker boxes
    //console.log(data);
    setBotoxTypeSelect(data);
  };

  const getBotoxImageFromChild = (data) => {
    //This is to get data from the BotoxMapImage component to change the 
    //console.log(data);
    //console.log(props.sendToMap.dict_botox_map_src_image);
    //setBotoxImageSelect(data);
    //setBotoxImageSelect(text);

    const srcProp = props.sendBotoxtoModal.dict_botox_map_src_image
    //const srcProp = userPrefCtx.dictBotoxMapSrcImage;
    //console.log(srcProp)
    //console.log(props.sendBotoxtoModal?.dict_botox_map_src_image);
    //console.log(userPrefCtx?.dictBotoxMapSrcImage)
    //console.log(srcProp.length)
    let base64Data = []
    let fileNameArray = []

    for(let i = 0; i < srcProp?.length; i++){
      // console.log(srcProp[i].id)
      // console.log(typeof srcProp[i].id);
      // console.log(typeof data);
      if(srcProp[i].id.toString() === data){
        //base64Data.push(srcProp[i].image_base64)
        fileNameArray.push(srcProp[i].file_name)
        //console.log(srcProp[i].image_base64)
      } else {
        //console.log(srcProp[i].id+ ' doesnt equal ' + data)
      }
    }

  //console.log(base64Data);
  //console.log(fileNameArray[0]);

  // const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${fileNameArray[0]}`
  const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${fileNameArray[0]}?dict_company_code_id=${companyCtx.company.id}`

  //console.log(imageURL);

  setBotoxImageSelect(imageURL);
  //setBotoxImageSelect(changeImageURL);
  //console.log(base64Data[0])

  }

  const onRemove = (index) => {
    setInputList(inputList.filter((_, i) => i !== index));
  };

  const Input = ({ onRemove, index }) => {
    const onRmvBtnClick = () => {
      onRemove(index);
    };
    return (
      <div>
        <div className={styles.add_notes_padding}>
          <Form.Control size="lg" type="text" placeholder="Other Info" />
        </div>
        <div className={styles.add_notes_padding}>
          <Button 
              id="remove-notes"
              className={`btn btn-secondary`}
              onClick={() => onRmvBtnClick()}
          >
            Remove
          </Button>
        </div>
        {console.log("input function")}
      </div>
    );
  };

  const onAddBtnClick = (event) => {
    setInputList(inputList.concat(<Input key={inputList.length} onRemove={onRemove} />));
  };
  const saveForm = (event) => {
    setNoteState(noteRef.current.value);
  }

  const changeColorValueSourceFunction = (event) => { 


  }

  const includeColorScaleChanged = () => {
    const newState = !isColorScaleIncluded
    setIsColorScaleIncluded(newState)

    const updateColorCheckPreferences = async (newPref) => {
        const userPrefBotoxColorCheckURL = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/botox_color_check`;
        const response = await fetch(userPrefBotoxColorCheckURL, {
            method: "PATCH",
            body: JSON.stringify({
                //"dict_botox_map_src_image_id": e.target.value
                "check_colors_botox": newPref,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
        }).then((res) => res.json())
        .then((data) => {
           //console.log('returning: ', data);
           //This is to trigger the visit useEffect after the patch call is made
           //props.visitTrigger(data);
           //console.log(data);
        })
        .catch((err) => {
            console.log(err.message);
        });
    }
    updateColorCheckPreferences(newState)
    
  }

  const handleCheckboxChange = async () => {
    setIsChecked(!isChecked); // Toggle the checked status

    const userPrefBotoxCheckURL = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/botox_check`;

   // console.log(!isChecked)

    const response = await fetch(userPrefBotoxCheckURL, {
      method: "PATCH",
      body: JSON.stringify({
        //"dict_botox_map_src_image_id": e.target.value
        "check_pat_name_botox": !isChecked
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userPrefCtx.bearerToken}`,
        auth: authCtx.authMode,
      },
    }).then((res) => res.json())
    .then((data) => {
      //console.log('returning: ', data);
      //This is to trigger the visit useEffect after the patch call is made
      //props.visitTrigger(data);
      //console.log(data);
    })
    .catch((err) => {
      console.log(err.message);
    });

  };

  return (
    <>
    {/* <div> */}
      <ErrorDialogModal
        setOpen={showError}
        close={() => setShowError(false)}
        componentName={'Botox Map'}
        customMessage={errorDialogMessage}
        // openFromChild={() => setShowError(true)}
      />
      <Button /*className='mx-2'*/ /*variant="secondary"*/
       size="sm"
       className={styles.enlighten_panel_button}
       onClick={() => props.enlightenPanelButtonClicked()}
       >
          Enlighten Panel
      </Button>
      <BsFillPlusSquareFill onClick={() => {
            setColorValueSource('default');
            //setting the id to null when the new botox button is clicked
            setEditData([null]);
            setShow2(true);
        }} className="icon-pointer"/>

      {/* <BsFillPlusSquareFill onClick={handleShow} className="icon-pointer"/> */}
      {/* <BsPencilSquare onClick={handleEditShow} className="icon-pointer"/> */}
      <BsPencilSquare onClick={() => handleEditShow(setShow2)} className="icon-pointer"/>
      <BsTrash3 
        onClick={openDeleteModal} 
        className="icon-pointer"
      />

{/* Sergey: the following line if uncommented will show seconf "+" that will open the original (written by Drew) BotoxMap */}
{/* <BsPencilSquare onClick={() => handleEditShow(setShow)} className="icon-pointer"/> */}



      <PromptBotoxMapDelete 
        setOpen={openDeleteBotoxModal} 
        close={closeDeleteModal}
        //sendID={props.sendDictDiagID}
        sendID={props.sendSelectedBotoxIds[0]}
        //getChild={getDeleteFromChild}
        getChild={sendDeletetoParent}
      /> 

      {show2 && 
      
        <VisualTreatmentModal2 
            show={show2} 
            hide={()=>setShow2(false)}
            sendBotoxtoModal={props.sendBotoxtoModal}
            getModal={props.getModal}
            editData={editData}
            sendBotoxTypeID={props.sendBotoxTypeID}
            deleteTrigger={props.deleteTrigger}
        
        />
      }




      {/* </div> */}
      <Modal size="fullscreen" show={show} onHide={handleClose} animation={false}
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
      >
        <Modal.Header closeButton 
          className={styles.modal_header}
        >

          <h4>Visual Treatment Map</h4>
        </Modal.Header>
            <Modal.Body>
                <div className="top_row_botox_area">
                    <Container className={styles.full_width_container}>
                        <Row className='d-flex align-items-center'> 
                            <Col>
                                <div className={styles.top_row_dropdowns}>
                                    <span>Botox Type:</span>
                                    <BotoxType
                                        sendBotoxPointerToParent={pullDataFromBotoxType}
                                        sendEditBotoxMapData={editData}
                                        setColorValueSource={(val) => setColorValueSource(val)}
                                    />
                                </div>
                            </Col>
            
                            <Col>
                            {editData?.botox_map_image_data === undefined && (
                                <div className={styles.top_row_dropdowns}>
                                    <h4>Botox Map Image:</h4>
                                    <BotoxMapImage 
                                        sendBotoxImageToParent={getBotoxImageFromChild}
                                        visitTrigger={props.visitTrigger}
                                    />
                                </div>
                                )}
                            </Col>

                            <Col>
                                <label>
                                    <input
                                    type="checkbox"
                                    id="botox-map-drawing_mode"
                                    checked={isColorScaleIncluded} 
                                    onChange={includeColorScaleChanged} 
                                    />
                                    Show Color Scale on Save
                                 </label>
                            </Col>

                            <Col>
                                <label>
                                    <input
                                    type="checkbox"
                                    id="botox-map-name-checkbox"
                                    checked={isChecked} // Bind the checked status to the state variable
                                    onChange={handleCheckboxChange} // Attach the event handler
                                    />
                                    Add Name to Map
                                </label>
                            </Col>
                            {/* <div className="col">
                                <DatePicker 
                                selected={startDate} 
                                onChange={(date) => setStartDate(date)} 
                                //onChange={(date) => getDate(date)} 
                                className="datepickerstyle" 
                                />
                            </div> */}
                            {/* {props.sendID.botox_map_image_data === undefined && (
                            <div className="col">
                            <DictBotoxSrcImageUpload 
                            visitTrigger={props.visitTrigger}
                            />
                            </div>
                            )} */}
                        </Row>
                    </Container>
                </div>
          <BotoxMap 
            setChild={getBotoxImageID} 
            sendEditBotoxMapData={editData}
            ref={childRef}
            sendBotoxTypeID={props.sendBotoxTypeID}
            visitTrigger={props.visitTrigger}
            sendImageChange={botoxImageSelect}
            sendNoteRef={noteRef}
            close={() => handleClose()}
            //This will show the error dialog modal when there is a problem with the botox map
            openErrorFromChild={() => setShowError(true)}
            sendAddNameCheck={isChecked}
            colorScaleIncluded={isColorScaleIncluded}
          />
          {/* <div className="container"> */}
          <div className={styles.botox_color_box}>
            <ColorBox 
              sendEditBotoxMapData={editData}
              sendSelection={botoxTypeSelect}
              sendColorValueSource={colorValueSource}
            />
          </div>
          {/* </div> */}
          <div className="container">
          <div className={styles.notes_div}>
            {/* <Form.Control
              onSubmit={saveForm}
              ref={noteRef}
              size="lg"
              type="text"
              placeholder="Other Info"
              defaultValue={editData?.botox_map_other_desc}
            /> */}
            <textarea 
              className={styles.notes_text_area}
              placeholder="Other Info"
              ref={noteRef}
              defaultValue={editData?.botox_map_other_desc}
            />
          </div>
          {/* {inputList}
            <Button 
                id="add-notes"
                className={`btn btn-primary`}
                onClick={() => onAddBtnClick()}
            >
              Add Notes
            </Button> */}
            </div>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClickChild}>
            Save As Today's Treatment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VisualTreatmentModal;