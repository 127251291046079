import { useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState, useRef } from "react";

import { useOktaAuth } from "@okta/okta-react";

import PatientContext from "../context/patientContext";
import visitPanelContext from "../context/visitPanelContext";
import DictionaryContext from "../context/dictionaryContext";
import userPrefContext from "../context/userPrefContext";
import CopyUserPrefContext from "../context/copyUserPrefContext";
import CompanyContext from "../context/companyContext";
// import VisitWithPrefContext from "../context/visitWithPrefContext";

import { Allotment } from "allotment";
import "allotment/dist/style.css";

import Button from "react-bootstrap/Button";
import { BsFillCameraFill } from "react-icons/bs";
import { trackPromise } from 'react-promise-tracker'
//import { Panel } from 'react-bootstrap';
import "../ui/stylesheets/Visit.css";
import "allotment/dist/style.css";

import SearchBar from "../ui/components/search/SearchBar";

import VisitPanels from "../ui/components/panels/VisitPanels";

import VisitUploadImage from "../ui/components/fileuploads/VisitUploadImage";
import PatientInformation from "../ui/components/patient_information/PatientInformation";

import NoImagePromptEnlightenPanel from "../ui/components/modals/NoImagePromptEnlightenPanel";

import AllVisits from "../ui/components/visits/AllVisits";

import VisitDialog from "../ui/components/visits/VisitDialog";
import EnlightenPanel from "../ui/components/enlighten_panel/EnlightenPanel";
import FileUpload from "../ui/components/fileuploads/FileUpload";
import { usePromiseTracker } from "react-promise-tracker"
import LoadingSpinner from '../ui/components/shared/LoadingSpinner'
import AllNotes from '../ui/components/notes/AllNotes'
import PatientComments from "../ui/components/patient_comments/PatientComments";
import AuthContext from "../context/authContext";
import { isTokenExpired } from "../utils/Utils";
import LoginBoxDB from "../LoginBoxDB";
import ErrorDialogModal from "../ui/components/modals/ErrorDialogModal";
import ProviderFollowupListModal from "../ui/components/actions/ProviderFollowupListModal";
import ProviderFollowupModal from "../ui/components/actions/ProviderFollowupModal";

import FollowupListModal from "../ui/components/actions/FollowupListModal";
import FollowupModal from '../ui/components/actions/FollowupModal'
import EditImage from "../ui/components/modals/EditImage";
// import EditImageMultiple from "../ui/components/modals/EditImageMultiple";
import EditImages from  "../ui/components/modals/EditImages";
import ConfirmDialog from "../ui/components/shared/ConfirmDialog";
import { BsFillPlusSquareFill, BsFillExclamationTriangleFill } from 'react-icons/bs'
import styles from './Visit.module.css'
// import IdleTimerModal from "../ui/components/modals/IdleTimerModal";

/*
 * This component will call multiple APIs
 * Botox Type(dict_services/visit) api will be passed to the VisitPanels, which will then be passed to the VisualTreatmentModal, and then to the Botox Visit component
 */

const Visit = (props) => {
    // console.log("Visit page")


  const patCtx = useContext(PatientContext);
  const visitPanelCtx = useContext(visitPanelContext);
  const dictCtx = useContext(DictionaryContext);
//   const { setAllVisitWithPrefFunction } = useContext(VisitWithPrefContext);
//   const { setAllUserPreferenceFunction, setBearerTokenFunction, bearerToken } =
//     useContext(userPrefContext);
  const userPreferenceCtx = useContext(userPrefContext);
  const CopyUserPreferenceCtx = useContext(CopyUserPrefContext);
  const authCtx = useContext(AuthContext)
  const companyCtx = useContext(CompanyContext)
  //console.log('printing the patient token for testing: ', patCtx.pat_token)

  const { authState, oktaAuth } = useOktaAuth();
  const [token, setToken] = useState(null);
  const [info, setInfo] = useState([{}]);
  const [userPreference, setUserPreference] = useState();
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [patientToken, setPatientToken] = useState();
  const [updateImage, setUpdateImage] = useState([]);

  const [triggerUseEffect, setTriggerUseEffect] = useState();
  const [triggerUseEffectOkta, setTriggerUseEffectOkta] = useState();

  const [botoxSRC, setBotoxSRC] = useState();
  const [dictVisit, setDictVisit] = useState([]);
  const [visitWithPrefState, setVisitWithPrefState] = useState([]);
  const [showVisitDialog, setShowVisitDialog] = useState(false);
  const [selectedVisitID, setSelectedVisitID] = useState(-1);
  const [visitsLoadedTime, setVisitsLoadedTime] = useState(new Date());

  // used for showing selected images in the Enlighten Panel
  // contains list of the images to show
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedIntraopImages, setSelectedIntraopImages] = useState([]);
  const [selectedBotoxImages, setSelectedBotoxImages] = useState([]);
  const [selectedBotoxIDs, setSelectedBotoxIDs] = useState([]);
  const [selectedBotoxTypeIDs, setSelectedBotoxTypeIDs] = useState([]);
  const [selectedBotoxObjects, setSelectedBotoxObjects] = useState([]);

  const [combinedSelectedImages, setCombinedSelectedImages] = useState([]);
  const [enlightenPanelVisible, setEnlightenPanelVisible] = useState(false);
  const [enlightenPanelNoImagePrompt, setEnlightenPanelNoImagePrompt] = useState(false);
  const [allNotes, setAllNotes] = useState([])
  const [selectedIcon, setSelectedIcon] = useState('camera_icon_90');
  const [acquireMediaOrientation, setAcquireMediaOrientation] = useState(90);
  const [newProfPicData, setNewProfPicData] = useState();
  const [visitPanelMountStatus, setVisitPanelMountStatus] = useState();
  const [showError, setShowError] = useState(false)
  const [errorDialogComponent, setErrorDialogComponent] = useState();
  const [errorDialogMessage, setErrorDialogMessage] = useState();
  const [errorDialogMessageAcquireMedia, setErrorDialogMessageAcquireMedia] = useState();
  const [disableMobileVisitsTabButton, setDisableMobileVisitsTabButton] = useState(true);
  const [disableMobileCommentsTabButton, setDisableMobileCommentsTabButton] = useState(false);
  //const [triggerAllVisits, setTriggerAllVisits] = useState();
  const refNotesSection = useRef()
  //const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [visitImagesBeingEdited, setVisitImagesBeingEdited] = useState(false)
  const [showOnlyVisitImagesShouldBeSelectedMsg, setShowOnlyVisitImagesShouldBeSelectedMsg] = useState(false)

  const headers = () => { return { Authorization: `Bearer ${token}`, mode: "no-cors", auth: authCtx.authMode }};


//   const [showFollowupForm, setShowFollowupForm] = useState(false)
  const [providerFollowupMode, setProviderFollowupMode] = useState('hide')
  const [selectedProviderFollowup, setSelectedProviderFollowup] = useState()


  const [followupMode, setFollowupMode] = useState('hide')
  const [selectedFollowup, setSelectedFollowup] = useState()

    const dbAuthRestore = (authMode) => {
        try {
            let data = localStorage.getItem("db-auth-access-return")
            // console.log("restoring Session - local storage", data, "!!data", !!data)
            if (!!data) {
                data = JSON.parse(data)
                // console.log("restoring Session - parsed data", data, "!!data.access_token", !!data.access_token, "data.access_token", data.access_token)
                
                if (!!data.access_token && !isTokenExpired(data.access_token)) {
                    
                    // console.log("restoring Session - data", data)
                    // userPreferenceCtx.setBearerTokenFunction(data.access_token)
                    authCtx.setToken(data.access_token)
                    authCtx.setIsAuthenticated(true)
                    
                    authCtx.setAuthMode(authMode)

                    const uInfo = {
                        first_name: data.first_name,
                        last_name: data.last_name,
                        is_him_user: data.is_him_user,
                        is_ipad_user: data.is_ipad_user,
                        is_readonly: data.is_readonly,
                        is_site_admin: data.is_site_admin,
                        is_superuser: data.is_superuser,
                        is_writeuser: data.is_writeuser,
                        name: data.first_name + ' ' + data.last_name,
                        // email: 'unknown',
                        // email_verified: false,
                        family_name: data.last_name,
                        given_name: data.first_name,
                        // locale: '',
                        // preferred_username: '',
                        // zoneinfo: '',
                    }
        
                    authCtx.setUserInfo(uInfo)
                    // setUserInfo(uInfo)
                    // setIsAuthenticated(true)
                    // setToken(data.accessToken)
                }
            }
            
        }
        catch (e) {
            console.log(e)
        }
        
    }

  //This will retrieve patient information from useNavigate
  const patient = useLocation();
//   console.log("Visit - patient", patient);
  const navigate = useNavigate()

  const record =
    "Name: " +
    patient.state?.firstName +
    " " +
    patient.state?.lastName +
    ", MRN: " +
    patient.state?.mrn +
    ", DOB: " +
    patient.state?.dob +
    ", Gender: " +
    patient.state?.gender;

  const nameString = patient.state?.firstName + " " + patient.state?.lastName;
  const dobString = patient.state?.dob;
  const genderString = patient.state?.gender;
  const mrnString = patient.state?.mrn;

  const visitPatToken = patient.state?.patientToken;

  //Setting the patient token to context
  patCtx.pat_token = patient.state?.patientToken;
  patCtx.pat_first = patient.state?.firstName
  patCtx.pat_last = patient.state?.lastName,
  patCtx.pat_middle = patient.state?.middleName
  patCtx.pat_mrn = patient.state?.mrn
  patCtx.pat_dob = patient.state?.dob
  patCtx.pat_gender = patient.state?.gender
  patCtx.pat_id = patient.state?.patId
  patCtx.pat_profile_image_filename = patient.state?.profileImageFileName
  patCtx.pat_email = patient.state?.email

//   console.log("visit page - authCtx", authCtx, "patient.state?.authMode", patient.state?.authMode)

  useEffect(() => {
    if (!!!authCtx.authMode && !!patient.state?.authMode) {
        authCtx.setAuthMode(patient.state.authMode)
    }

    if (!!!authCtx.authMode && !!patient.state?.authMode && patient.state.authMode !== 'OKTA') {
        dbAuthRestore(patient.state.authMode)
      }
  }, [authCtx.authMode, patient.state?.authMode])
  



    


    // useEffect(() => {
    //     if (!!!authCtx.authMode ) {
    //         // possibly refreshed page
    //         if (localStorage.getItem("db-auth-token-storage") !== null) {
    //             // this can be refersh - reload data from the local storage
    //             let data = localStorage.getItem("db-auth-access-return")
    //             let dbTokenStorage = localStorage.getItem("db-auth-token-storage")
    //             let oktaUInfo = localStorage.getItem("db-auth-oktaInfo")
    //             let authenticationInfo = localStorage.getItem("db-auth-authenticationInfo")

    //             if (!!data && !!dbTokenStorage && !!oktaUInfo && !!authenticationInfo) {
    //                 dbAuthRestore()
    //             }
    //         }
    //     } 
    // }, [authCtx.authMode])

    useEffect(() => {
      const handleWindowResize = () => {
        setWindowSize([window.innerWidth, window.innerHeight]);
      };
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    function detectDevice() { 
      //https://www.quora.com/Is-there-an-algorithm-in-ReactJS-to-detect-whether-the-device-is-a-phone-or-a-computer
      //This is a function to detect what kind of device is being used
      var userAgent = navigator.userAgent.toLowerCase(); 
      if (userAgent.search("iphone") > -1 || 
          //userAgent.search("ipad") > -1 || 
          //userAgent.search("ipod") > -1 || 
          userAgent.search("android") > -1) { 
          return "mobile"; 
      } else if (userAgent.search("ipad") > -1){ 
          return "ipad"; 
      }
      else { 
          return "desktop"; 
      } 
    } 


  useEffect(() => {

    // console.log(detectDevice());
    userPreferenceCtx.setDeviceTypeFunction(detectDevice());

    if (authCtx.authMode === 'OKTA') {
        if (!authState) {
        //console.log('!authState of useEffect')
        // When user isn't authenticated, forget any user info
        setToken(null);
        } else {
        //console.log('else statement of useEffect, setting bearer')
        setToken(oktaAuth.getAccessToken());
        //setPatientToken(patient.state?.patientToken);
        }
    } else {
        if (authCtx.isAuthenticated && token !== authCtx.token) {
            setToken(authCtx.token)
        } else {
            setToken(null)
        }
    }
  }, [authCtx.authMode, authState, oktaAuth, patCtx.pat_token, triggerUseEffectOkta, triggerUseEffect, CopyUserPreferenceCtx.userPreference]);


  useEffect(async () => {

    //console.log('testing useeffect', token, authCtx.token)

    if (token) {
      //setting the bearer token in context if it exists
      if (userPrefContext.bearerToken !== token) {
        userPreferenceCtx.setBearerTokenFunction(token);
      }
      

       //console.log("bearer", token);

      //This is for the botox type and dictionary diagnosis
      const visitDictURL =
        process.env.REACT_APP_SERVER + "/api/v1/dict_services/visit";

      const botoxSRCDictURL = process.env.REACT_APP_SERVER +
        "/api/v1/dict_services/dict_botox_map_src_image";

      const userPrefDictURL =
        process.env.REACT_APP_SERVER + "/api/v1/user_preferences/me";

    //   const visitWithPrefDictURL =
    //     process.env.REACT_APP_SERVER + "/api/v1/dict_services/visit_with_pref";

    //   const headers = { Authorization: `Bearer ${token}` };

      //I'm defining these arrays before fetching so that I can have values ready to pass to context for the dict panels so that they dont appear before user pref
      const panelNameArr = [];
      const panelFullNameArr = [];
      const panelIDArr = [];


      try {
        const response = await fetch(visitDictURL, { headers: headers() });
        if (response.status === 200) {
          const responseData = await response.json();
           //console.log("Visit response", responseData)

          //This is being passed to the botox map, there may be dependencies based on this
          setInfo(responseData);
          //this is to send the dict_diag, and rest of the visit information to child components, please use this useState
          setDictVisit(responseData);

          // set dictionary context - this is necessary for the dictionary populate on Refresh button
          dictCtx.setAllDictionaries(responseData);

          //This is to get the panels properly sorted and determined before the panels are called in the child component
          const sortPanels = responseData.dict_panel;

          //console.log(sortPanels)
          //This is to sort the visit panels based on the db values
          sortPanels.sort((a, b) =>
            a.sort_position > b.sort_position ? 1 : -1
          );
          // const panelNameArr = [];
          // const panelFullNameArr = [];
          // const panelIDArr = [];

          for (let i = 0; i < sortPanels?.length; i++) {
            //It will push them into their arrays if the panel is set to true for being displayed
            if (sortPanels[i]?.is_active === true) {
              //console.log('true in the for loop');
              panelNameArr.push(sortPanels[i]?.display_name_short);
              panelFullNameArr.push(sortPanels[i]?.display_name);
              panelIDArr.push(sortPanels[i]?.id);
            }
          }

          //Setting the context values early on, currenly have this commented out because I'm pushing the values to an array and then
          //Going to set them to context when the userpref get request is being called
          // visitPanelCtx.dict_display_name = panelNameArr;
          // visitPanelCtx.dict_display_name_full = panelFullNameArr;
          // visitPanelCtx.dict_panel_id = panelIDArr;
        } else {
          console.log("not 200, visitDictURL failed");
        }
      } catch (e) {
        console.log(e);
      }
      try {
        const response = await fetch(userPrefDictURL, { headers: headers() });
        if (response.status === 200) {
          const responseData = await response.json();
            //console.log('userPrefDictURL: ', responseData)

            //Setting the context
            
            // important!!!
            // due to Python nature arrays of strings returned not as correct json - they have single quotes around array elements
            // instead of double quotes
            // so array properties of UserPreference object are strings. We are going to fix it here and load them as arrays
            let uPref = responseData.UserPreference
            Object.keys(uPref).forEach(key => {
                if (typeof uPref[key] === "string" && uPref[key].startsWith('[')) {
                    uPref[key] = JSON.parse(uPref[key].replace(/'/g, '"'))
                }
            })
            // console.log("Dashboard UserPref response (/api/v1/user_preferences/me)", {...responseData, UserPreference: uPref})
            //   userPreferenceCtx.setAllUserPreferenceFunction(responseData);
            userPreferenceCtx.setAllUserPreferenceFunction({...responseData, UserPreference: uPref});
        //   setUserPreference(responseData);
            setUserPreference({...responseData, UserPreference: uPref});

          const sortUserPrefPanels = responseData['UserPreferenceDictPanel'];

          sortUserPrefPanels.sort((a, b) =>
            a['UserPreferenceDictPanel'].sort_position > b['UserPreferenceDictPanel'].sort_position ? 1 : -1
          );

          const userPrefPanelNameArr = [];
          const userPrefPanelFullNameArr = [];
          const userPrefPanelIDArr = [];

          for (let i = 0; i < sortUserPrefPanels?.length; i++) {
            //It will push them into their arrays if the panel is set to true for being displayed
            if (sortUserPrefPanels[i]['DictPanel']?.is_active === true) {
              userPrefPanelNameArr.push(sortUserPrefPanels[i]['DictPanel']?.display_name_short);
              userPrefPanelFullNameArr.push(sortUserPrefPanels[i]['DictPanel']?.display_name);
              //userPrefPanelIDArr.push(sortUserPrefPanels[i]['DictPanel']?.id);
              userPrefPanelIDArr.push(sortUserPrefPanels[i]['UserPreferenceDictPanel']?.dict_panel_id);

            }
          }

          //console.log('userPrefPanelNameArr : ', userPrefPanelNameArr)
          //console.log('userPrefPanelFullNameArr : ', userPrefPanelFullNameArr)
          //console.log('userPrefPanelIDArr : ', userPrefPanelIDArr)

          //Setting the context values early on
          visitPanelCtx.user_pref_display_name = userPrefPanelNameArr;
          visitPanelCtx.user_pref_display_name_full = userPrefPanelFullNameArr;
          visitPanelCtx.user_pref_panel_id = userPrefPanelIDArr;

          //I'm setting up the values here so that the dict panels dont appear before the user pref panels if they both exist
          visitPanelCtx.dict_display_name = panelNameArr;
          visitPanelCtx.dict_display_name_full = panelFullNameArr;
          visitPanelCtx.dict_panel_id = panelIDArr;

        } else {
          console.log("not 200, userPrefDictURL failed");
        }
      } catch (e) {
        console.log(e);
      }
      try {
        const response = await fetch(botoxSRCDictURL, { headers: headers() });
        if (response.status === 200) {
          const responseData = await response.json();
          //console.log(responseData)
          setBotoxSRC(responseData);
          //This is being passed to the botox map, there may be dependencies based on this
        } else {
          console.log("not 200, botoxSRCDictURL failed");
        }
      } catch (e) {
        console.log(e);
      }

    } else {
      //console.log('There is no token but the patCtx.pat_token is ', patCtx.pat_token)
    }
  }, [token, patCtx.pat_token, triggerUseEffect, CopyUserPreferenceCtx.userPreference]);

  useEffect(() => {
    //This is needed to update the AllVisits when patient is changed through searchbar
    setVisitsLoadedTime(new Date())
    // scroll notes section to the top when patient changed
    if (!!refNotesSection && !!refNotesSection.current) {
        // console.log("Visit refNotesSection", refNotesSection.current)
        refNotesSection.current.scrollTop = 0
    }
    
  }, [patCtx.pat_token]);

  const callUseEffectTriggerFunction = (e) => {
    setTriggerUseEffect(e);
    // console.log('callUseEffectTriggerFunction ', e)
  };

  const newVisitDialog = () => {
    console.log('clicked on new information')
    setSelectedVisitID(-1);
    setShowVisitDialog(true);
  };

  const visitSelected = (id) => {
    // console.log("Visit - visitSelected: id", id)
    setSelectedVisitID(id);
    setShowVisitDialog(true);
  };

  // show images selected for enlighten panel
  const showEnlightenPanel = () => {
    // console.log("Enlighten Panel will show images:", selectedImages);
    //setEnlightenPanelVisible(true);
    if(combinedSelectedImages.length > 0) {
      setEnlightenPanelVisible(true);
    } else {
      setEnlightenPanelNoImagePrompt(true);
    }
  };

  const hideEnlightenPanel = () => {
    setEnlightenPanelVisible(false);
    // clear image selection
    setSelectedImages([]);
    setSelectedBotoxObjects([]);
    //setSelectedBotoxImages is controlling the CSS/selection of the botox panel thumbnails
    setSelectedBotoxImages([]);
    //need to reset the number of ids so that the selection doesnt go wrong in visual treatment modal
    setSelectedBotoxIDs([]);

    //This is to reset the number of botoxtypes for the edit data in the botox map save
    setSelectedBotoxTypeIDs([]);

    //This is to reset the selected intraop images useState
    setSelectedIntraopImages([])

    setCombinedSelectedImages([]);
  };

  const hideNoImagePrompt = () => {
    setEnlightenPanelNoImagePrompt(false);

  };

  // visit image selected/un-selected for enlighten panel
  const visitImageSelected = (image) => {
    // add or remove image from array
    // console.log("Visit - image selected", image)
    let newImageList = [...selectedImages];
    if (newImageList.indexOf(image) == -1) {
      // add element
      newImageList.push(image);
    } else {
      // remove element
      const index = selectedImages.indexOf(image);
      if (index >= 0) {
        newImageList.splice(index, 1);
      }
    }
    setSelectedImages(newImageList);
    //console.log(newImageList);
  };

  const IntraopImageSelected = (image) => {
    // add or remove image from array
    let newIntraopImageList = [...selectedIntraopImages];
    if (newIntraopImageList.indexOf(image) == -1) {
      // add element
      newIntraopImageList.push(image);
    } else {
      // remove element
      const index = selectedIntraopImages.indexOf(image);
      if (index >= 0) {
        newIntraopImageList.splice(index, 1);
      }
    }
    setSelectedIntraopImages(newIntraopImageList);
    //console.log(newIntraopImageList);
  };

    // visit image selected/un-selected for enlighten panel
    const botoxPanelImageSelected = (image, image_id, botox_type_id, object) => {
      // add or remove image from array
      let newBotoxImageList = [...selectedBotoxImages];
      let newBotoxIDList = [...selectedBotoxIDs];
      let newBotoxTypeIDList = [...selectedBotoxTypeIDs];
      let newBotoxObjectList = [...selectedBotoxObjects];

      //The order in which the respective image and id come into this function is the same, which is why I haven't implemented another if statement
      if (newBotoxImageList.indexOf(image) == -1) {
        // add element
        newBotoxImageList.push(image);
        //adding id to the array
        newBotoxIDList.push(image_id);

        newBotoxTypeIDList.push(botox_type_id);

        newBotoxObjectList.push(object);
      } else {
        // remove element
        const index = selectedBotoxImages.indexOf(image);
        if (index >= 0) {
          newBotoxImageList.splice(index, 1);
          newBotoxIDList.splice(index, 1);
          newBotoxTypeIDList.splice(index, 1);
          newBotoxObjectList.splice(index, 1);
        }
      }
      setSelectedBotoxImages(newBotoxImageList);
      setSelectedBotoxIDs(newBotoxIDList);
      setSelectedBotoxTypeIDs(newBotoxTypeIDList);
      setSelectedBotoxObjects(newBotoxObjectList);
      //console.log(newBotoxImageList);
       //console.log(newBotoxObjectList);
    };

    const combinedImageSelected = (img, imgId, botoxTypeId, image) => {
      //This will accept images from the botox map and AllVisits component
      // add or remove image from array
    //   console.log('combinedImageSelected image is: ',image);

      let newImageList = [...combinedSelectedImages];
      if (newImageList.indexOf(image) == -1) {
        // add element
        newImageList.push(image);
      } else {
        // remove element
        const index = combinedSelectedImages.indexOf(image);
        if (index >= 0) {
          newImageList.splice(index, 1);
        }
      }
      setCombinedSelectedImages(newImageList);
      //console.log('combinedImageSelected: ', newImageList);
    };

    const httpHeaders = (bearerToken) => {
        return {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            accept: "application/json",
            auth: authCtx.authMode,
        }
    }
    // delete visit - called from Visit Dialog
    const deleteVisit = (visitId) => {
        const url = `${process.env.REACT_APP_SERVER}/api/v1/visits/${visitId}`
        fetch(url, {
            headers: httpHeaders(userPreferenceCtx.bearerToken),
            method: "DELETE",
        })
        .then((response) => response.json())
        .then((data) => {
            // console.log("Visit - visit deleted", data)
            // reload visits
            setVisitsLoadedTime(new Date())
            // hide the dialog
            hideEnlightenPanel()
        })
        // .then((data) => console.log(data))
        .catch((error) => { console.error(`Error: deleting visit with id ${visitId}: `, error) })

        // // reload visits
        // setVisitsLoadedTime(new Date())
        // // hide the dialog
        // hideEnlightenPanel()
    }

    //
    const setProfilePicture = () => {
        if (selectedImages.length === 1) {

            const setProfileImage = async () => {
                const setProfileImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/upload/set_as_profile_image/${patCtx.pat_token}?file_name=${selectedImages[0].file_name}`

                // console.log("setting up profile image", selectedImages[0].file_name)
                const responseData = await fetch(setProfileImageURL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        //use context here instead of the usestate token
                        Authorization: `Bearer ${userPreferenceCtx.bearerToken}`,
                        auth: authCtx.authMode,
                    },
                    body: {},
                })
                //.then((res) => res.json())
                .then((res) => {
                  if (!res.ok) {
                     throw new Error('Unprocessable Entity: ' + res.statusText);
                  }
                  return res.json();
                })
                .then((data) => {
                    // console.log("Setup Profile image", data)
                    //This is to send data to the parent
                    patCtx.pat_profile_image_filename = data.file_name
                    setNewProfPicData(data)
                    setUpdateImage(Math.random());
                    
                    //props.getChild(data);
                })
                .catch((err) => {
                    console.log(err.message)
                    //errorDialogComponent
                    setErrorDialogComponent('Set Profile Picture Button')
                    setShowError(true);
                });

            }
            
            setProfileImage()

            // alert(`Not implemented yet! Will set image id=${selectedImages[0].id}, file=${selectedImages[0].file_name} as profile`)
        } else {
            alert("Please select one image!")
        }
    }


  const clickAcquireMedia = async (e) => {
    // console.log(userPrefContext)
    // console.log(userPreference);
    // const acquireMediaURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/acquire_rotate_media/${patCtx.pat_token}?orientation=${acquireMediaOrientation}`;
    // const acquireMediaURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/acquire_rotate_media/${patCtx.pat_token}?rotation_angle=${acquireMediaOrientation}`;
    // const acquireMediaURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/acquire_rotate_media/${patCtx.pat_token}?dict_company_code_id=${userPreference.UserPreference.dict_company_code_id}&rotation_angle=${acquireMediaOrientation}`;
    const acquireMediaURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/acquire_rotate_media/${patCtx.pat_token}?dict_company_code_id=${companyCtx.company.id}&rotation_angle=${acquireMediaOrientation}`;
    
    let headers2 = {
      //"Content-Type": "application/json",
      //use context here instead of the usestate token
      Authorization: `Bearer ${userPreferenceCtx.bearerToken}`,
      accept: "application/json",
      auth: authCtx.authMode,
    };

    // const responseData = await fetch(acquireMediaURL, {
    //   method: "POST",
    //   // body: JSON.stringify({
    //   // }),
    //   // body: JSON.stringify({
    //   //   orientation: acquireMediaOrientation
    //   // }),
    //   headers: {
    //     "Content-Type": "application/json",
    //     //use context here instead of the usestate token
    //     Authorization: `Bearer ${userPreferenceCtx.bearerToken}`,
    //     auth: authCtx.authMode,
    //   },
    // })
    //   //.then((res) => res.json())
    //   .then((res) => {
    //     // Check for error response
    //     if (!res.ok) {
    //       console.log(res)
    //       throw new Error('Unprocessable Entity: ' + res.statusText);
    //     }
    //     return res.json();
    //   })
    //   .then((data) => {
    //     console.log(data);
    //     setVisitsLoadedTime(new Date())
    //     //This is to send data to the parent
    //     //props.getChild(data);
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //     setErrorDialogComponent('Acquire Media')
    //     setErrorDialogMessageAcquireMedia('Oops! Something went wrong. Please check if the media file exists in the shared folder.<br>' +
    //     'If the media file exists and youre still experiencing issues, kindly get in touch with the administrator for assistance.<br> Thank you!')
    //     setShowError(true);
    //   });

    //using the spinner to update the allvisits
      // trackPromise(
      //   fetch(acquireMediaURL, {
      //   headers: headers2,
      //   method: "POST",
      //   //body: files,
      // })
      trackPromise(
        fetch(acquireMediaURL, {
        headers: headers2,
        method: "POST",
        //body: files,
      })

          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
              // Handle the successful response here
            })
            .catch((error) => {
              setErrorDialogComponent('Acquire Media')
              setErrorDialogMessageAcquireMedia('Oops! Something went wrong. Please check if the media file exists in the shared folder.<br>' +
              'If the media file exists and youre still experiencing issues, kindly get in touch with the administrator for assistance.<br> Thank you!')
              setShowError(true);
            })
        )
    
  };

  const clickCameraIcon = (e) => {
    // console.log('clicked on camera icon', e.target)
    setSelectedIcon(e.target);
  }

  const handleClick = (iconName, rotation_angle) => {
    // console.log(iconName);
    setSelectedIcon(iconName);

    //let rotation_angle = 0;

    // if (orientation == 3){

    //     rotation_angle = 180
    // }
    // else if (orientation == 6){

    //     rotation_angle = 270
    // }
    // else if (orientation == 8){

    //     rotation_angle = 90
    // }
    // else{

    //     rotation_angle = 0
    // }

    // console.log('rotation_angle is ', rotation_angle)
    // setAcquireMediaOrientation(rotation_angle);
    setAcquireMediaOrientation(rotation_angle);
  };

  const isSelected = (iconName) => {
    return selectedIcon === iconName;
  };

  const removeItem = (data) => {
    //This is supposed to remove a specific object from combinedSelectedImages useState so that a botox map can be removed independently from the rest of the images
    const updatedArray = combinedSelectedImages.filter(item => 
      ((item.id !== data) || ((item.id === data) && (!item.botox_map_json_image_id)))
      );
    setCombinedSelectedImages(updatedArray);
    // console.log(data);
  }

  const getSearchBarData = (data) => {
    // console.log('visit search bar data is: ', data)
    setTriggerUseEffectOkta(data);
  }

  const getClearBotoxIDForDelete = (data) => {
    //This function is to clear the useState for the botox panels so that the delete functionality can work properly
    //console.log(data)
    //removeItem(data);
    
    setSelectedBotoxObjects([]);
    //setSelectedBotoxImages is controlling the CSS/selection of the botox panel thumbnails
    setSelectedBotoxImages([]);
    //need to reset the number of ids so that the selection doesnt go wrong in visual treatment modal
    setSelectedBotoxIDs([]);


    //This is to reset the number of botoxtypes for the edit data in the botox map save
    setSelectedBotoxTypeIDs([]);

    //This is to reset the selected intraop images useState
    setSelectedIntraopImages([])

    //In order to get to this point only 1 botox map must be selected and deleted, therefore there should be no botox maps images in the combined useState
    //But images from the split pane may be present instead, so it will be set to the selectedImages
    setCombinedSelectedImages(selectedImages)
  };


    const hideDbLogin = () => {
        setShowDBLogin(false)
    }

    const getVisitPanelsMountCheck = (e) => {
      // console.log('clicked on camera icon', e.target)
      // setSelectedIcon(e.target);
    //   console.log(e)
      setVisitPanelMountStatus(e);
    }

    const closeVisitDialog = () => {
        setShowVisitDialog(false)
        setSelectedVisitID(-1)
    }

    // console.log("Visit: selectedVisitID",selectedVisitID, "showVisitDialog", showVisitDialog)

    const selectProviderFollowup = (fu) => {
        // console.log("Visit - selected fu form", fu)
        setSelectedProviderFollowup(fu)
        setProviderFollowupMode('edit')
    }


    const selectFollowup = (fu) => {
        // console.log("Visit - selected fu form", fu)
        setSelectedFollowup(fu)
        setFollowupMode('edit')
    }

    const clickCommentsMobileTab = () => {
      setDisableMobileVisitsTabButton(false);
      setDisableMobileCommentsTabButton(true);
    }
    const clickVisitsMobileTab = () => {
      setDisableMobileVisitsTabButton(true);
      setDisableMobileCommentsTabButton(false);
    }

    // edit images button clicked. Make sure only images selected or show a pop up message dialog
    const onEditImageClicked = () => {
        if (selectedIntraopImages.length > 0 || selectedBotoxImages.length > 0) {
            setShowOnlyVisitImagesShouldBeSelectedMsg(true)
        } else {
            setVisitImagesBeingEdited(true)
        }
    }

  return (
    <>
    <div className="visit_main_div">
      {((oktaAuth.authStateManager._authState?.isAuthenticated === true) ||
        (authCtx.authMode !== 'OKTA' && authCtx.isAuthenticated)) && (
        <Allotment vertical defaultSizes={[400, 300]}>
          <div className="top-pane">
            <br />
              <div className="row row_padding_left">
                <div className="col-sm-1 col-md-2 col-lg-1 col_image_div">
                  <VisitUploadImage 
                    //visitTrigger={callUseEffectTriggerFunction}
                    newImageUpdatefromButton={newProfPicData}
                    updateImagefromParent={updateImage}
                    //sendTrigger={}
                  />
                  </div>
                <div className="col-sm-2 col-md-4 col-lg-3 col_div_record">               
                  <PatientInformation />
                </div>
                { windowSize[0] > 451 &&
                
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="visit_searchbar_div">
                    <SearchBar randomProps={Math.random()}/>
                  </div>
                  <div className="visit_buttons_outer_div">
                    <div className="visit-lower-pane-button">
                        <FileUpload
                        //adding the bearertoken so I can get rid of okta in the component
                        bearerToken={token}
                        />
                    </div>
                    <div className="visit-lower-pane-button acquire_media_div">
                      <Button
                        onClick={clickAcquireMedia}
                        size="sm"
                      >
                        Acquire Media
                      </Button>
                        <div className='d-flex justify-content-center'>
                        <BsFillCameraFill 
                          className={`camera_icon ${selectedIcon === "camera_icon" ? "camera_icon_selected" : ""}`}
                          onClick={() => handleClick("camera_icon", 0)}
                        />
                        <BsFillCameraFill 
                          className={`camera_icon_90 ${selectedIcon === "camera_icon_90" ? "camera_icon_selected" : ""}`} 
                          onClick={() => handleClick("camera_icon_90", 90)}
                        />
                        <BsFillCameraFill 
                          className={`camera_icon_180 ${selectedIcon === "camera_icon_180" ? "camera_icon_selected" : ""}`} 
                          onClick={() => handleClick("camera_icon_180", 180)}
                        />
                        <BsFillCameraFill 
                            className={`camera_icon_270 ${selectedIcon === "camera_icon_270" ? "camera_icon_selected" : ""}`} 
                            // onClick={() => handleClick("camera_icon_270", 6)}
                            onClick={() => handleClick("camera_icon_270", 270)}
                        />
                      </div>
                    </div>
                    <div className="visit-lower-pane-button">
                      <Button
                        onClick={newVisitDialog}
                        size="sm"
                      >
                        New Information
                      </Button>
                    </div>
                    <div className="visit-lower-pane-button">
                      <Button
                        onClick={showEnlightenPanel}
                        size="sm"
                      >
                        Enlighten Panel
                      </Button>
                    </div>
                    <div className="visit-lower-pane-button">
                        <Button
                            onClick={setProfilePicture}
                            disabled={selectedImages.length !== 1}
                            size="sm"
                        >
                            Set As Profile Picture
                        </Button>
                    </div>
                
                    {!!companyCtx.company.show_prov_followup_sheets &&
                        <div className="visit-lower-pane-button">
                            <Button
                                onClick={() => setProviderFollowupMode('list')}
                                size="sm"
                            >
                                Provider Follow Up
                            </Button>
                        </div>
                    }

                    {!!companyCtx.company.show_prov_followup_sheets &&
                        <div className="visit-lower-pane-button">
                            <Button
                                onClick={() => setFollowupMode('list')}
                                size="sm"
                            >
                                Follow Up
                            </Button>
                        </div>
                    }
                    
                    {/* </div> */}
                  {/* </div> */}
                </div>
                <div className="col-sm-3 col-md-0 col-lg-2"></div>
              </div>
              }
              <br />
              <div>
                {/* <div className="row row_padding_left2">
                  <div className="col-sm-12 col-md-12 col-lg-12"><h4>hello</h4></div>
                </div> */}
                </div>
            </div>
  
              {/* { userPreferenceCtx.dictDeviceType === 'mobile' && windowSize.current[0] < 451 && */}
              { userPreferenceCtx.dictDeviceType === 'mobile' && windowSize[0] < 451 &&
            <div className="row row_padding_left2">
              {/* <h4>hello</h4> */}
              <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="visit_searchbar_div">
                    <SearchBar randomProps={Math.random()}/>
                  </div>
                  <div className="visit_buttons_outer_div">
                    <div className="visit-lower-pane-button">
                        <FileUpload
                        //adding the bearertoken so I can get rid of okta in the component
                        bearerToken={token}
                        />
                    </div>
                    {/* <div className="visit-lower-pane-button acquire_media_div">
                      <Button
                        onClick={clickAcquireMedia}
                        size="sm"
                      >
                        Acquire Media
                      </Button>
                        <div>
                        <BsFillCameraFill 
                          className={`camera_icon ${selectedIcon === "camera_icon" ? "camera_icon_selected" : ""}`}
                          onClick={() => handleClick("camera_icon", 0)}
                        />
                        <BsFillCameraFill 
                          className={`camera_icon_90 ${selectedIcon === "camera_icon_90" ? "camera_icon_selected" : ""}`} 
                          onClick={() => handleClick("camera_icon_90", 90)}
                        />
                        <BsFillCameraFill 
                          className={`camera_icon_180 ${selectedIcon === "camera_icon_180" ? "camera_icon_selected" : ""}`} 
                          onClick={() => handleClick("camera_icon_180", 180)}
                        />
                        <BsFillCameraFill 
                            className={`camera_icon_270 ${selectedIcon === "camera_icon_270" ? "camera_icon_selected" : ""}`} 
                            // onClick={() => handleClick("camera_icon_270", 6)}
                            onClick={() => handleClick("camera_icon_270", 270)}
                        />
                      </div>
                    </div> */}
                    <div className="visit-lower-pane-button">
                      <Button
                        onClick={newVisitDialog}
                        size="sm"
                      >
                        New Information
                      </Button>
                    </div>
                    <div className="visit-lower-pane-button">
                      <Button
                        onClick={showEnlightenPanel}
                        size="sm"
                      >
                        Enlighten Panel
                      </Button>
                    </div>
                    {/* <div className="visit-lower-pane-button">
                        <Button
                            onClick={setProfilePicture}
                            disabled={selectedImages.length !== 1}
                            size="sm"
                        >
                            Set As Profile Picture
                        </Button>
                    </div> */}
                    {!!companyCtx.company.show_prov_followup_sheets &&
                        <div className="visit-lower-pane-button">
                            <Button
                                onClick={() => setProviderFollowupMode('list')}
                                size="sm"
                            >
                                Provider Follow Up
                            </Button>
                        </div>
                    }

                    {!!companyCtx.company.show_prov_followup_sheets &&
                        <div className="visit-lower-pane-button">
                            <Button
                                onClick={() => setFollowupMode('list')}
                                size="sm"
                            >
                                Follow Up
                            </Button>
                        </div>
                    }
                    
                    {/* </div> */}
                  {/* </div> */}
                </div>
                </div>
            </div>
            }
            <VisitPanels
              sendrecord={record}
              //These are the props for the panels
              //This sendBotox appears to be a duplicate of the visit db call, will change later and implement context as there are too many dependent child components
              sendBotox={info}
              //sendToken={patCtx.pat_token}
              //I'm sending the patient token through use navigate
              sendToken={visitPatToken}
              bearerToken={token}
              sendDictVisit={dictVisit}
              sendUserPreference={userPreference}
              sendBotoxSRC={botoxSRC}
              //onImageSelect={(image, image_id) => botoxPanelImageSelected(image, image_id)}
              onImageSelect={(img, imgId, botoxTypeId, object) => {
                botoxPanelImageSelected(img, imgId, botoxTypeId, object), 
                combinedImageSelected(img, imgId, botoxTypeId, object['BotoxMapImage'])
               }}
               onIntraopImageSelect={(image) => {
                IntraopImageSelected(image)
                combinedImageSelected('', '', '', image)
              }}
              selectedIntraopImages={selectedIntraopImages}
              selectedBotoxImages={selectedBotoxImages}
              sendSelectedBotoxIds={selectedBotoxIDs}
              sendBotoxTypeID={selectedBotoxTypeIDs}
              sendBotoxObjects={selectedBotoxObjects}
              sendSelectedIntraopImages={selectedIntraopImages}
              //This prop is a callback to trigger the useEffect
              visitTrigger={callUseEffectTriggerFunction}
              enlightenPanelButtonClicked={showEnlightenPanel}
              clearBotoxIDForDelete={getClearBotoxIDForDelete}
              checkIfComponentMounted={getVisitPanelsMountCheck}
            />
            <LoadingSpinner onEndOfPromise={() => setVisitsLoadedTime(new Date())} />
          </div>
          { userPreferenceCtx.dictDeviceType !== 'mobile' &&
          <Allotment.Pane
            minSize={50}
            //maxsize={1000}
          >
            <Allotment vertical>
              <Allotment.Pane>
                <Allotment horizontal>
                  <Allotment.Pane>
                    <div className="h-100 w-100 y-overflow-auto">
                      { visitPanelMountStatus === true &&
                      <AllVisits
                        // dict_visit={dictVisit}
                        onVisitSelect={visitSelected}
                        //This is the image data to get combined images in the enlighten panel
                        onImageSelect={(image) => {
                          visitImageSelected(image)
                          combinedImageSelected('', '', '', image)
                        }}
                        selectedImages={selectedImages}
                        enlightenPanelButtonClicked={showEnlightenPanel}
                        last_loaded_time={visitsLoadedTime}
                        setAllNotes={setAllNotes}
                        editImageClicked={() => onEditImageClicked()}
                      />
                       }
                    </div>
                  </Allotment.Pane>
                  <Allotment.Pane>
                  { visitPanelMountStatus === true &&
                    <div className="h-100 w-100 y-overflow-auto" ref={refNotesSection}>
                      {/* <div > */}
                      <PatientComments 
                        last_loaded_time={visitsLoadedTime}
                      />
                      {/* </div> */}
                    <AllNotes
                        // onVisitSelect={visitSelected}
                        // onImageSelect={(image) => visitImageSelected(image)}
                        // selectedImages={selectedImages}
                        // enlightenPanelButtonClicked={showEnlightenPanel}
                        // last_loaded_time={visitsLoadedTime}
                        notes={allNotes}
                      />
                    </div>
                  }
                  </Allotment.Pane>
                </Allotment>
              </Allotment.Pane>
            </Allotment>
          </Allotment.Pane>
          }
          { userPreferenceCtx.dictDeviceType === 'mobile' &&
          <Allotment.Pane
            minSize={50}
            //maxsize={1000}
          >
            <Allotment vertical>
              <Allotment.Pane>
                <Allotment horizontal>
                  <Allotment.Pane>
                    <div className="h-100 w-100 y-overflow-auto">
                      <div className="mobile_buttons_div">
                        <Button
                        style={{ flex: 1 }} 
                          size="lg"
                          disabled={disableMobileVisitsTabButton}
                          onClick={() => clickVisitsMobileTab()}
                        >
                          Visits
                        </Button>
                        <Button
                        style={{ flex: 1 }} 
                        size="lg"
                        disabled={disableMobileCommentsTabButton}
                        onClick={() => clickCommentsMobileTab()}
                        >
                          Comments/Notes
                        </Button>
                      </div>
                      { visitPanelMountStatus === true && disableMobileVisitsTabButton === true &&
                      <AllVisits
                        // dict_visit={dictVisit}
                        onVisitSelect={visitSelected}
                        //This is the image data to get combined images in the enlighten panel
                        onImageSelect={(image) => {
                          visitImageSelected(image)
                          combinedImageSelected('', '', '', image)
                        }}
                        selectedImages={selectedImages}
                        enlightenPanelButtonClicked={showEnlightenPanel}
                        last_loaded_time={visitsLoadedTime}
                        setAllNotes={setAllNotes}
                        editImageClicked={() => setVisitImagesBeingEdited(true)}
                      />
                       }
                    { visitPanelMountStatus === true && disableMobileCommentsTabButton === true &&
                    <div className="h-100 w-100 y-overflow-auto" ref={refNotesSection}>
                      <PatientComments 
                        last_loaded_time={visitsLoadedTime}
                      />
                    <AllNotes
                        // onVisitSelect={visitSelected}
                        // onImageSelect={(image) => visitImageSelected(image)}
                        // selectedImages={selectedImages}
                        // enlightenPanelButtonClicked={showEnlightenPanel}
                        // last_loaded_time={visitsLoadedTime}
                        notes={allNotes}
                      />
                    </div>
                  }
                    </div>
                  </Allotment.Pane>
                  {/* <Allotment.Pane>
                  { visitPanelMountStatus === true &&
                    <div className="h-100 w-100 y-overflow-auto" ref={refNotesSection}>
                      <PatientComments 
                        last_loaded_time={visitsLoadedTime}
                      />
                    <AllNotes
                        // onVisitSelect={visitSelected}
                        // onImageSelect={(image) => visitImageSelected(image)}
                        // selectedImages={selectedImages}
                        // enlightenPanelButtonClicked={showEnlightenPanel}
                        // last_loaded_time={visitsLoadedTime}
                        notes={allNotes}
                      />
                    </div>
                  }
                  </Allotment.Pane> */}
                </Allotment>
              </Allotment.Pane>
            </Allotment>
          </Allotment.Pane>
          }

          
        </Allotment>
      )}

            <VisitDialog
                show={showVisitDialog}
                visit_id={selectedVisitID}
                // visit_dict={dictVisit}
                onHide={closeVisitDialog}
                onSave={() => setVisitsLoadedTime(new Date())}
                onVisitDelete={deleteVisit}
                last_loaded_time={visitsLoadedTime}
            />

            {visitImagesBeingEdited &&
                // <EditImageMultiple 
                //     show={visitImagesBeingEdited} 
                //     images={selectedImages} 
                //     hide={() => setVisitImagesBeingEdited(false)}
                //     onSave={() => {
                //         setVisitsLoadedTime(new Date())
                //         setSelectedImages([]);
                //     }}
                // />

                <EditImages 
                    show={visitImagesBeingEdited} 
                    images={selectedImages} 
                    hide={() => {
                        setVisitImagesBeingEdited(false);
                        setVisitsLoadedTime(new Date())
                        setSelectedImages([]);
                    }}
                    onSave={() => {
                        setVisitsLoadedTime(new Date())
                        setSelectedImages([]);
                    }}
                />
            }


            <EnlightenPanel
                images={selectedImages}
                sendBotoxImages={selectedBotoxObjects}
                sendCombinedImages={combinedSelectedImages}
                patient_id={patCtx.pat_id} // DEBUG - should come from Patient Context
                show={enlightenPanelVisible}
                onHide={hideEnlightenPanel}
            />

            {providerFollowupMode === 'list' && 
                <ProviderFollowupListModal 
                    show={true} 
                    onCreateNew={() => setProviderFollowupMode('new')} 
                    onClose={() => setProviderFollowupMode('hide')} 
                    onSelect={selectProviderFollowup}
                />
            }
            {(providerFollowupMode === 'new' || providerFollowupMode === 'edit') && 
                <ProviderFollowupModal 
                    show={true} 
                    mode={providerFollowupMode}
                    onClose={(new_mode) => setProviderFollowupMode(new_mode)} 
                    fu={selectedProviderFollowup}/>
            }


            {followupMode === 'list' && 
                <FollowupListModal 
                    show={true} 
                    onCreateNew={() => setFollowupMode('new')} 
                    onClose={() => setFollowupMode('hide')} 
                    onSelect={selectFollowup}
                />
            }
            {(followupMode === 'new' || followupMode === 'edit') && 
                <FollowupModal 
                    show={true} 
                    mode={followupMode}
                    onClose={(new_mode) => setFollowupMode(new_mode)} 
                    fu={selectedFollowup}/>
            }
      <NoImagePromptEnlightenPanel
        show={enlightenPanelNoImagePrompt}
        onHide={hideNoImagePrompt}
      />
            <NoImagePromptEnlightenPanel
                show={enlightenPanelNoImagePrompt}
                onHide={hideNoImagePrompt}
            />
            { showError === true &&
            <ErrorDialogModal
              setOpen={showError}
              close={() => setShowError(false)}
              //componentName="Acquire Media"
              componentName={errorDialogComponent}
              //<br> needs to be placed for the custom new lines
              // customMessageSplitLinesAquireMedia={'Oops! Something went wrong. Please check if the media file exists in the shared folder.<br>' +
              // 'If the media file exists and youre still experiencing issues, kindly get in touch with the administrator for assistance.<br> Thank you!'}
              customMessageSplitLinesAquireMedia={errorDialogMessageAcquireMedia}
            />
            }

            <ConfirmDialog 
                show={showOnlyVisitImagesShouldBeSelectedMsg}
                iconColor='orange'
                icon={<BsFillExclamationTriangleFill />}
                title='Visit Image Editing'
                // modal_class='diags-modal-delete-confirm-modal'
                title_class={styles['alert-title']}
                // footer_class='diags-modal-delete-confirm-title'
                prompt='Only visit images can be edited. Please deselect non-visit (Visual Tratment Map and/or Intraop) images.'
                prompt_class={styles['alert-prompt-black']}
                cancel_button_text=''
                confirm_button_text='Close'
                confirm_button_variant='primary'
                onClose={() => setShowOnlyVisitImagesShouldBeSelectedMsg(false)}
            />

        </div>

        {/* <LoginBoxDB 
            /*show={showDBLogin} onHide={hideDbLogin}*/ 
            // authMode={authCtx.authMode} mode='autolock' showMessage="You've been locked out due to inactivity. Sign in to unlock your session" 
            /*onLogin={onUnlock}*/
        // /> 
        }
    </>
    );
};
export default Visit;
