import React, { useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BotoxMap2 from '../fabric/BotoxMap2';
import ColorBox from '../colorboxes/ColorBox';
import BotoxType2 from '../dropdowns/BotoxType2';
import BotoxMapImage2 from '../dropdowns/BotoxMapImage2';
import styles from '../../stylesheets/VisualTreatmentModal.module.css'
import userPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext';
import CompanyContext from '../../../context/companyContext';




function VisualTreatmentModal2(props) {

    const userPrefCtx = useContext(userPrefContext);
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext);

    let DEFAULT_IMAGE = "";
    const publicImage = "botox-map-full.png";

    const defaultImageFromDict = userPrefCtx.dictBotoxMapSrcImage?.file_name;

    if(defaultImageFromDict === null || defaultImageFromDict === undefined){
        DEFAULT_IMAGE = publicImage;
    } else {
        DEFAULT_IMAGE = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${defaultImageFromDict}?dict_company_code_id=${companyCtx.company.id}`
    }


    const [showError, setShowError] = useState(false);
    const [botoxTypeSelect, setBotoxTypeSelect] = useState();
    const [addName, setAddName] = useState(false);
    const [isColorScaleIncluded, setIsColorScaleIncluded] = useState(false)
    const [botoxImageSelect, setBotoxImageSelect] = useState(DEFAULT_IMAGE);
    const [colorValueSource, setColorValueSource] = useState('data');

    const noteRef = useRef(null);
    const childRef = useRef();

    const [size, setSize] = useState({width:0, height:0})

    const [botoxMapHeight, setBotoxMapHeight] = useState(0)



    const getBotoxImageID = (data) => {
        //This is the function to get the color value from the BotoxType(child) component
        props.getModal(data)
    }



    const close = () => {
        props.hide()
        props.deleteTrigger(Math.random());
    }



    const includeColorScaleChanged = () => {
        const newState = !isColorScaleIncluded
        setIsColorScaleIncluded(newState)
    
        const updateColorCheckPreferences = async (newPref) => {
            const userPrefBotoxColorCheckURL = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/botox_color_check`;
            const response = await fetch(userPrefBotoxColorCheckURL, {
                method: "PATCH",
                body: JSON.stringify({
                    "check_colors_botox": newPref,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                    auth: authCtx.authMode,
                },
            })
            .then((res) => res.json())
            .then((data) => {
               //console.log('returning: ', data);
               //This is to trigger the visit useEffect after the patch call is made
               //props.visitTrigger(data);
               //console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        }
        updateColorCheckPreferences(newState)
    }



    function handleClickChild() {
        childRef.current.childFunction();
        props.hide();
    }



    const getBotoxImageFromChild = (data) => {
        //This is to get data from the BotoxMapImage component to change the 
        const srcProp = props.sendBotoxtoModal.dict_botox_map_src_image
        let base64Data = []
        let fileNameArray = []
    
        for(let i = 0; i < srcProp?.length; i++) {
            if(srcProp[i].id.toString() === data){
                fileNameArray.push(srcProp[i].file_name)
            } 
        }
    
        const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${fileNameArray[0]}?dict_company_code_id=${companyCtx.company.id}`
        setBotoxImageSelect(imageURL);   
    }




    const innerDimensions = (node) => {
        var computedStyle = getComputedStyle(node)
        // console.log("Box Sizing", computedStyle.boxSizing)
        let width = node.clientWidth // width with padding
        let height = node.clientHeight // height with padding
      
        height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom) + parseFloat(computedStyle.borderTop) + parseFloat(computedStyle.borderBottom)

        // console.log("Padding Bootom", parseFloat(computedStyle.paddingBottom))
        width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight) + parseFloat(computedStyle.borderLeft) + parseFloat(computedStyle.borderRight)
        return { height, width }
      }


      const getOffset = (el) => {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY
        };
      }


    useEffect(() => {
        if(!!userPrefCtx?.userPreference?.check_pat_name_botox){
            setAddName(true);
        }
    
        setIsColorScaleIncluded(!!userPrefCtx.userPreference.check_colors_botox)
    
    }, [userPrefCtx.userPreference])


    const calculateAvailableSize = () => {
        // console.log("Visual Treatment 2 - getting size", size)
        const dialog = document.getElementById('VisualTreatmentModal2')
        // console.log("Visual Treatment 2 - dialog", dialog)
        if (!!dialog) {
            const modalBody = dialog.getElementsByClassName('modal-body')[0]
            const top_controls = document.getElementById('top_controls')
            const top_controlsH = top_controls.getBoundingClientRect().height;
            const note = noteRef.current
            const noteH = note.getBoundingClientRect().height;
            const color_box = document.getElementById('visual_treatment_modal_color_box')
            const colorBoxH = color_box.getBoundingClientRect().height;

            // calculate height and width available for Botox Map
            const dialogBodySize = innerDimensions(modalBody)
            const height = dialogBodySize.height - top_controlsH - colorBoxH - noteH
            const width = dialogBodySize.width

            // console.log("Visual Treatment 2 - returning size", {width: width, height: height})

            return {width: width, height: height}
        }

        return {width: 0, height: 0}
    } 


    useEffect(() => {
        if (size.height === 0 && size.width === 0) {
            const sz = calculateAvailableSize()
            setSize(sz)
        }
    }, [size])
    

    return (
        <>
        <Modal 
            fullscreen={true}
            show={props.show} onHide={close} animation={false}
            id='VisualTreatmentModal2'
            style={{ visibility: (showError) ? 'hidden' : 'visible' }}
        >
            <Modal.Header closeButton className={styles.modal_header}><h4>Visual Treatment Map</h4></Modal.Header>
            <Modal.Body className='d-flex /*align-items-center*/ justify-content-center'>
                <div id='mbody'>
                <div className="top_row_botox_area">
                    <Container className={styles.full_width_container}>
                        <Row id='top_controls' className='d-flex align-items-center'> 
                            <Col className='col-lg-3 col-sm-4'>
                                <BotoxType2
                                    sendBotoxPointerToParent={(data)=>setBotoxTypeSelect(data)}
                                    sendEditBotoxMapData={props.editData}
                                    setColorValueSource={(val) => setColorValueSource(val)}
                                />
                            </Col>
    
                            <Col className='col-lg-4 col-sm-4'>
                                {props.editData?.botox_map_image_data === undefined && (
                                    <BotoxMapImage2 
                                            sendBotoxImageToParent={getBotoxImageFromChild}
                                            visitTrigger={props.visitTrigger}
                                            sendBotoxTypeID={props.sendBotoxTypeID}
                                    />

                                )}
                            </Col>

                            <Col className='col-lg-3 col-sm-2'>
                                <label>
                                    <input
                                    type="checkbox"
                                    id="botox-map-drawing_mode"
                                    checked={isColorScaleIncluded} 
                                    onChange={includeColorScaleChanged} 
                                    />
                                    Show Color Scale on Save
                                    </label>
                            </Col>

                            <Col className='col-lg-2 col-sm-2'>
                                <label>
                                    <input
                                        type="checkbox"
                                        id="botox-map-name-checkbox"
                                        checked={addName} 
                                        onChange={()=>setAddName(!addName)} 
                                    />
                                    Add Name to Map
                                </label>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='botox-map-wrapper'>
                    {(botoxMapHeight > 0 || botoxMapHeight == 0) &&
                        <BotoxMap2
                            setChild={getBotoxImageID} 
                            sendEditBotoxMapData={props.editData}
                            ref={childRef}
                            sendBotoxTypeID={props.sendBotoxTypeID}
                            visitTrigger={props.visitTrigger}
                            sendImageChange={botoxImageSelect}
                            sendNoteRef={noteRef}

                            //This will show the error dialog modal when there is a problem with the botox map
                            openErrorFromChild={() => setShowError(true)}
                            sendAddNameCheck={addName}
                            colorScaleIncluded={isColorScaleIncluded}

                            // new props
                            size={size}
                            calculateAvailableSize={calculateAvailableSize}
                        />
                    }
                </div>
                
                <div id='visual_treatment_modal_color_box' className={styles.botox_color_box}>
                    <ColorBox 
                        sendEditBotoxMapData={props.editData}
                        sendSelection={botoxTypeSelect}
                        sendColorValueSource={colorValueSource}
                    />
                </div>
                
                <div className={styles.notes_div}>
                    <textarea 
                        className={styles.notes_text_area}
                        placeholder="Other Info"
                        ref={noteRef}
                        defaultValue={props.editData?.botox_map_other_desc}
                    />
                </div>
                </div>
            </Modal.Body>

            <Modal.Footer className={styles.modalFooter}>
                <Button variant="secondary" onClick={close}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClickChild}>
                    Save As Today's Treatment
                </Button>
            </Modal.Footer>
      </Modal>
        </>
    )
}

export default VisualTreatmentModal2
