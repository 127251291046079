import React, {useState, useContext} from 'react'
import CompanyContext from '../../../context/companyContext'

function VisitImageEdit(props) {
    const companyCtx = useContext(CompanyContext)

    const imageURL = (filename, patient_id, mode) => `${process.env.REACT_APP_SERVER}/api/v1/image_edits/image/${props.mode}_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`
    // const imageURL = (filename, patient_id, mode) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/image/${filename}?patient_id=${patient_id}`
    // console.log("VisitImage - image url", imageURL(props.image.file_name, props.patient_id))
    const toggleSelected = () => {
        props.onImageSelect(props.image)
    }


    return (
        <div className={` ${props.isImageSelected ? 'border-selected' : 'border-not-selected'}`} onClick={toggleSelected}>
            <img className='visit-image' src={imageURL(props.image.file_name, props.patient_id)}></img>
        </div>
    )
}

export default VisitImageEdit
