import React, { useState, useContext, useEffect } from 'react'
import DOMPurify from 'dompurify';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import pptxgen from 'pptxgenjs'
import CompanyContext from '../../../context/companyContext'
import exifr from 'exifr' 

// props:
// images: array of image objects to display
// patient_id

function EnlightenPanel(props) {
    // note additional "dummy" parameters in URLs for PPTX and downloading images. Read this article below
    // https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome#solution
    // TL;DR:
    // Problem:
    // If you've loaded an image in Chrome, Edge, Chromium or other Chromium-based browser, and the browser cached that image. When you call for that same image with the Access-Controll-Allow-Origin header (or crossOrigin="Anonymous" if you're doing it in JavaScript) - Chromium returns an error response because the initially cached image didn't have that header.
    // Solution:
    // When calling the image url with the crossOrigin="Anonymous" header, add a dummy GET parameter at the end of the URL. This will essentially change the resource, so Chrome won't look into the cache and will call the "new" url instead, giving you the image that you needed, but this time with the header that you wanted.
    // This solution not only fixes the issue in Chromium based browsers, but also doesn't change the way Firefox, Safari and other browsers view your app.
    
    const companyCtx = useContext(CompanyContext)
    
    const imageURL = (filename, patient_id) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`
    const imagePPURL = (filename, patient_id) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/image/normal_${filename}?patient_id=${patient_id}&mode=pptx&time=${new Date()}&dict_company_code_id=${companyCtx.company.id}`
    const imageDownloadURL = (filename, patient_id) => `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/image/${filename}?patient_id=${patient_id}&mode=download&dict_company_code_id=${companyCtx.company.id}`

    const botoxImageURL = (filename, patient_id) => `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`

    const selectImageURL = (filename, patient_id, object) => {

        let botoxURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`
        //let IntraopURL = `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/image/normal_${filename}?patient_id=${patient_id}`
        let IntraopURL = `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/image/${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`
        let otherImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`
        const imageEditURL = `${process.env.REACT_APP_SERVER}/api/v1/image_edits/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`
    
        if(object.botox_map_json_image_id != null){
            //console.log('its a botox image', botoxURL);
            //console.log('object.botox_map_json_image_id is ',object.botox_map_json_image_id)
            return botoxURL;
        }
        else if (object.is_intraop === true) {
            // console.log('filename.is_intraop: ', object.is_intraop)
            // console.log('intraop matches! ', IntraopURL);
            return IntraopURL;
        }
        else if (!!object.image_edit_json_id) {
            return imageEditURL
        }
        else {
            //console.log('not a botox image');
            return otherImageURL;
        }
    }

    const selectimagePPURL = (filename, patient_id, object) => {
        //console.log('its a botox image', object);

        let botoxPPURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}&mode=pptx&time=${new Date()}`
        //let IntraopPPURL = `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/image/normal_${filename}?patient_id=${patient_id}&mode=pptx&time=${new Date()}`
        let IntraopPPURL = `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/image/${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}&mode=pptx&time=${new Date()}`
        let otherImagePPURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}&mode=pptx&time=${new Date()}`
        const imageEditURL = `${process.env.REACT_APP_SERVER}/api/v1/image_edits/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`
    
        if(object.botox_map_json_image_id != null){
            //console.log('its a botox image', botoxPPURL);
            //console.log('object.botox_map_json_image_id is ',object.botox_map_json_image_id)
            return botoxPPURL;
        }
        else if (object.is_intraop === true) {
            return IntraopPPURL;
        }
        else if (!!object.image_edit_json_id) {
            return imageEditURL
        }
        else {
            // console.log('not a botox image - url', otherImagePPURL);
            return otherImagePPURL;
        }
    }

    const selectImageDownloadURL = (filename, patient_id, object) => {
        //console.log('its a botox image', object);

        let botoxURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/image/${filename}?patient_id=${patient_id}&mode=download&dict_company_code_id=${companyCtx.company.id}`
        //let IntraopURL = `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/image/${filename}?patient_id=${patient_id}&mode=download`
        let IntraopURL  = `${process.env.REACT_APP_SERVER}/api/v1/intra_op_images/image/${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}&mode=download`
        //let botoxPPURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/image/normal_${filename}?patient_id=${patient_id}&mode=pptx&time=${new Date()}`
        let otherImageURL = `${process.env.REACT_APP_SERVER}/api/v1/file_uploads/image/${filename}?patient_id=${patient_id}&mode=download&dict_company_code_id=${companyCtx.company.id}`
        const imageEditURL = `${process.env.REACT_APP_SERVER}/api/v1/image_edits/image/normal_${filename}?patient_id=${patient_id}&dict_company_code_id=${companyCtx.company.id}`
    
        if(object.botox_map_json_image_id != null){
            //console.log('its a botox image', botoxURL);
            //console.log('object.botox_map_json_image_id is ',object.botox_map_json_image_id)
            return botoxURL;
        }
        else if (object.is_intraop === true) {
            return IntraopURL;
        }
        else if (!!object.image_edit_json_id) {
            return imageEditURL
        }
        else {
            //console.log('not a botox image');
            return otherImageURL;
        }
    }
    

    //const [imgArray, setImgArray] = useState([]) 
    //const [botoxImgArray, setBotoxImgArray] = useState([])
    const [combinedState, setCombinedState] = useState([]);
    const [columnCount, setColumnCount] = useState(1)
    // const [showAlert, setShowAlert] = useState(false)
    // const [alertText, setAlertText] = useState('')
    const [enableButtons, setEnableButtons] = useState(true)

    useEffect(() => {
        // console.log("EnlightenPanel - props.sendCombinedImages", props.sendCombinedImages)
        /*
            Botox image code start
            This is to handle the botox map images separately to not cause issues with the other images
        */

        //if (props.sendBotoxImages !== null && props.sendBotoxImages.length > 0) {
            //console.log('props.sendBotoxImages is ', props.sendBotoxImages)

            // let botoximages = props.sendBotoxImages.sort((a,b) => new Date(a?.created_at) - new Date(b?.created_at))
            // // add slected property for each image
            // botoximages = botoximages.map(img => {
            //     return {...img, selected: true}
            // })
            // // if number of images >= 4 output in 4 columns; otherwise output in one row
            // const splitSize3 = props.sendBotoxImages.length >= 4 ? 4: props.sendBotoxImages.length
            // setColumnCount(splitSize3)
            // let botoxImagesSorted = []
            // for (let i = 0; i < botoximages.length; i += splitSize3) {
            //     botoxImagesSorted.push(botoximages.slice(i, i + splitSize3));
            // }

            // // console.log("**** sorted images ****", imagesSorted)

            // setBotoxImgArray([...botoxImagesSorted])

            /*
                This is for the combined images, this should stay
            */

            let combinedImages = props.sendCombinedImages.sort((a,b) => new Date(a?.updated_at) - new Date(b?.updated_at))
            // add slected property for each image
            combinedImages = combinedImages.map(img => {
                return {...img, selected: true}
            })
            // if number of images >= 4 output in 4 columns; otherwise output in one row
            const splitSize2 = props.sendCombinedImages.length >= 4 ? 4: props.sendCombinedImages.length
            setColumnCount(splitSize2)
            let combinedImagesSorted = []
            for (let i = 0; i < combinedImages.length; i += splitSize2) {
                combinedImagesSorted.push(combinedImages.slice(i, i + splitSize2));
            }

            // console.log("**** sorted images ****", imagesSorted)

            setCombinedState([...combinedImagesSorted])
        //}
        /* end */

}, [props.images.length, props.sendBotoxImages, props.sendCombinedImages])




    const getSelectedImagesList = (images) => {
        let selectedImages = images.map(row => {
            // element is an array, so loop over it
            const rowSelected = row.filter(img => img.selected === true)
            // return row.filter(img => img.selected === true)
            // console.log("ROW SELECTED", rowSelected)
            return rowSelected
        })
        return selectedImages.flat()
    }


    // print selected images
    //Sergey's code, please review and remove as needed
    // const handlePrint = () => {
    //     let selectedImages = getSelectedImagesList(imgArray)
    //     if (selectedImages.length > 0) { 
    //         let popupWin = window.open('', '_blank', 'scrollbars=yes,width=1020,height=1920')

    //         popupWin.document.open()
    //         const imagesHtml = selectedImages.map(img => {
    //             const i = document.getElementById(`img_${img.id}`)
    //             let height = 'auto'
    //             let width = 'auto'
    //             if (i !== null) {
    //                 if (i.naturalWidth >= i.naturalHeight) {
    //                     // landscape format
    //                     width = '100%'
    //                     height = 'auto'
    //                 } else {
    //                     // portrait format
    //                     height = '100%'
    //                     width = 'auto'
    //                 }
                    
    //             } 
    //             return (
    //                 `<div key="div_${img.id}" style="width: ${width}; height: ${height};"><img style="max-width:100%; max-height:100%;" src=${selectimagePPURL(img.file_name, props.patient_id, img)} /> <div>${new Date(img.updated_at).toLocaleString('en-US')}}</div></div>`
    //             )
    //         })
    //         popupWin.document.write('<html><head><style>@media print {div {break-inside: avoid;}}</style></head><body onload="window.print()">' + imagesHtml + '</body></html>')
    //         popupWin.document.close()
    //     }
    // }
    
//This selects images, botox, and intraop
    const handlePrintAll = () => {
        //This is to print the images from combinedState
        let selectedImages = getSelectedImagesList(combinedState)
        if (selectedImages.length > 0) { 
            let popupWin = window.open('', '_blank', 'scrollbars=yes,width=1020,height=1920')

            popupWin.document.open()
            const imagesHtml = selectedImages.map(img => {
                const i = document.getElementById(`img_${img.id}`)
                let height = 'auto'
                let width = 'auto'
                if (i !== null) {
                    if (i.naturalWidth >= i.naturalHeight) {
                        // landscape format
                        width = '100%'
                        height = 'auto'
                    } else {
                        // portrait format
                        height = '97%'
                        width = 'auto'
                    }
                    
                } 
                // return (
                //     `<div key="div_${img.id}" style="width: ${width}; height: ${height};"><img style="max-width:100%; max-height:100%;" src=${imagePPURL(img.file_name, props.patient_id)} /> <div>${new Date(img.updated_at).toLocaleString('en-US')}}</div></div>`
                // )
                return (
                    `<div key="div_${img.id}" style="width: ${width}; height: ${height};"><img style="max-width:100%; max-height:100%;" src=${selectimagePPURL(img.file_name, props.patient_id, img)} /> <div>${new Date(img.updated_at).toLocaleString('en-US')}</div></div>`
                )
            })
            popupWin.document.write('<html><head><style>@media print {div {break-inside: avoid;}}</style></head><body onload="window.print()">' + imagesHtml + '</body></html>')
            popupWin.document.close()
        }
    }



    // export selected images as a power point file
    //Sergey's code, please review and remove as needed
    // const handlePowerpoint = () => {
    //     let selectedImages = getSelectedImagesList(imgArray)

    //     if (selectedImages.length > 0) {
    //         let pptx = new pptxgen()
    //         pptx.layout = 'LAYOUT_4x3' // 10"x7.5"
    //         // console.log("Creating Powerpoint with images:", selectedImages)

    //         selectedImages.map(img => {
    //             const url=imagePPURL(img.file_name, props.patient_id)
    //             let imageWH = {w: 0, h: 0}
    //             const i = document.getElementById(`img_${img.id}`)
    //             if (i !== null) {
    //                 imageWH = {w: i.naturalWidth, h: i.naturalHeight}
    //             } 
    //             let slide = pptx.addSlide()

    //             // console.log("meta returned", imageWH)
    //             if (imageWH.h > imageWH.w) {
    //                 // portrait orientation - make height = 10, calculate width
    //                 slide.addImage({
    //                     path:url,
    //                     x: 0,
    //                     y: 0,
    //                     w: 7.7*imageWH.w/imageWH.h,
    //                     h: 7.5, // imageWH.h,
    //                     sizing: 'crop'
    //                 })
    //             } else {
    //                 // landscape orientation
    //                 slide.addImage({
    //                     path:url,
    //                     x: 0,
    //                     y: 0,
    //                     w: 10,
    //                     h: 10*imageWH.h/imageWH.w,
    //                     sizing: 'crop'
    //                 })
    //             }
                

    //         })

    //         pptx.writeFile({ fileName: "EnlightenMD" });
    //     }
        
    // }

    //This creates a powerpoint for images, botox, and intraop
    const handlePowerpointAll = () => {
        let selectedImages = getSelectedImagesList(combinedState)

        // get EXIF rotation data from the image and add it to the image object
        const getExif = async (url, image) => {
            let rotate = null
            let flip = null
            let rotation = await exifr.rotation(url)
            // console.log("****rotation*****", rotation)
            // image.rotate = rotation.deg || 0
            // image.flip = rotation.dimensionSwapped || false
            // image.url = url
            const exifImg = await {...image, rotate: rotation.deg || 0, flip: rotation.dimensionSwapped || false, url: url}
            // console.log("exifImg", exifImg)
            return await exifImg
        }

        const getAllExifs = async (images) => {
            let exifAllImages = images.map(async img =>  {
                const url=selectimagePPURL(img.file_name, props.patient_id, img)
                let oneImage = await getExif(url, img)
                return await oneImage
            })
            return await exifAllImages
        }

        (async () => {
            let allExifImages = await getAllExifs(selectedImages)
            Promise.all(allExifImages).then((images) => {
                // console.log("*** allExifImages ***", images)

                // create powerpoint here
                // create ppx
                let pptx = new pptxgen()
                pptx.layout = 'LAYOUT_4x3' // 10"x7.5"
                images.map(img => {
                    let imageWH = {w: 0, h: 0}
                    const i = document.getElementById(`img_${img.id}`)
                    if (i !== null) {
                        imageWH = {w: i.naturalWidth, h: i.naturalHeight}
    
                        let slide = pptx.addSlide()
        
                        
                        // console.log("Image before adding", img)
        
                        // console.log("meta returned", imageWH)
                        const size_koef = imageWH.h > imageWH.w ? imageWH.w/imageWH.h : imageWH.h/imageWH.w
                        if (imageWH.h > imageWH.w) {
                            // portrait orientation - make height = 10, calculate width
                            // heigh = 7.5", width = 7.7*imageWH.w/imageWH.h (unless we rotate)
                            // if rotate to 90 or 270 degrees then flip w and h sizes
                            slide.addImage({
                                path:img.url,
                                x: img.flip ? (7.7*size_koef-7.5)/2 : 0,
                                y: img.flip ? (7.5 - 7.7*size_koef)/2 : 0,
                                w: img.flip ? 7.5 : 7.7*size_koef,
                                h: img.flip ? 7.7*size_koef : 7.5,
                                sizing: 'crop',
                                rotate: img.rotate,
                            })
                        } else {
                            // landscape orientation
                            slide.addImage({
                                path:img.url,
                                x: img.flip ? (10*size_koef-10)/2 : 0,
                                y: img.flip ? (10- 10*size_koef)/2 : 0,
                                w: img.flip ? 10*size_koef : 10,
                                h: img.flip ? 10: 10*size_koef,
                                sizing: 'crop',
                                rotate: img.rotate
                            })
                        }
        
                    }
    
    
                })

                pptx.writeFile({ fileName: "EnlightenMD" })
            })
        })()
    }



        // let pptx = new pptxgen()
        // pptx.layout = 'LAYOUT_4x3' // 10"x7.5"
        // const generatePowerPoint = async () => {
        //     if (selectedImages.length > 0) {
                
        //         const slideW = 10
        //         const slideH = 7.5
        //         // console.log("Creating Powerpoint with images:", selectedImages)
    
        //         selectedImages.map(async (img) =>  {
        //             console.log("image:", img)
        //             const url=selectimagePPURL(img.file_name, props.patient_id, img)
        //             let imageWH = {w: 0, h: 0}
        //             const i = document.getElementById(`img_${img.id}`)
        //             if (i !== null) {
        //                 imageWH = {w: i.naturalWidth, h: i.naturalHeight}
        //             }
        //             // console.log("EnlightenPanel image info", i)
        //             let rotate = -1
        //             let flip = false
        //             const img_opts = {
        //                 path:url,
        //                 x: 0,
        //                 y: 0,
        //                 w: 7.7*imageWH.w/imageWH.h,
        //                 h: 7.5, // imageWH.h,
        //                 sizing: 'crop',
        //                 rotate: 0
        //             }
    
        //             const addImage = async (image) => {
        //                 await exifr.rotation(image).then(rot => {
        //                     // console.log("exifr: degree", rot.deg || 0)
        //                     // console.log("exifr: flip", rot.dimensionSwapped || false)
        //                     rotate = rot.deg || 0
        //                     flip = rot.dimensionSwapped || false

        //                     console.log("exifr: degree", rotate)
        //                     console.log("exifr: flip", flip)

        //                     let slide = pptx.addSlide()

        //                     const size_koef = imageWH.h > imageWH.w ? imageWH.w/imageWH.h : imageWH.h/imageWH.w

        //                     console.log("size_koef", size_koef, "vertical?", imageWH.h > imageWH.w)
        //                     if (imageWH.h > imageWH.w) {
        //                         // portrait orientation - make height = 10, calculate width
        //                         // heigh = 7.5", width = 7.7*imageWH.w/imageWH.h (unless we rotate)
        //                         // if rotate to 90 or 270 degrees then flip w and h sizes
        //                         const straight_h = 7.5
        //                         const straight_w = 7.7*size_koef
        //                         const flip = rotate === 90 || rotate === 270

        //                         const img_opts = {
        //                             path:url,
        //                             x: flip ? (7.7*size_koef-7.5)/2 : 0,
        //                             y: flip ? (7.5 - 7.7*size_koef)/2 : 0,
        //                             w: flip ? 7.5 : 7.7*size_koef,
        //                             h: flip ? 7.7*size_koef : 7.5,
        //                             sizing: 'crop',
        //                             rotate: rotate,
        //                         }
        //                         console.log("***flip", flip, "adding image", img_opts)
        //                         slide.addImage({
        //                             path:url,
        //                             x: flip ? (7.7*size_koef-7.5)/2 : 0,
        //                             y: flip ? (7.5 - 7.7*size_koef)/2 : 0,
        //                             w: flip ? 7.5 : 7.7*size_koef,
        //                             h: flip ? 7.7*size_koef : 7.5,
        //                             sizing: 'crop',
        //                             rotate: rotate,
        //                         })
        //                         console.log("slide", slide)
        //                     } else {
        //                         // landscape orientation
        //                         const straight_h = 10
        //                         const straight_w = 10*size_koef
        //                         const flip = rotate === 90 || rotate === 270
        //                         slide.addImage({
        //                             path:url,
        //                             x: flip ? (10*size_koef-10)/2 : 0,
        //                             y: flip ? (10- 10*size_koef)/2 : 0,
        //                             w: flip ? 10*size_koef : 10,
        //                             h: flip ? 10: 10*size_koef,
        //                             sizing: 'crop',
        //                             rotate: rotate
        //                         })
        //                     }
        //                 })
                        
        //             } 
        //             await addImage(url)
        //         })
    
        //         // pptx.writeFile({ fileName: "EnlightenMD" });
        //     }
        // }
        // generatePowerPoint().then(() => pptx.writeFile({ fileName: "EnlightenMD" }))
        
    // }







    // download selected images
    //Sergey's code, please review and remove as needed
    // const handleDownload = () => {
    //     let selectedImages = getSelectedImagesList(imgArray)
    //     // console.log("SELECTED IMAGES", selectedImages)

    //     selectedImages.map(img => {
    //         const url=imageDownloadURL(img.file_name, props.patient_id)
    //         downloadURI(url,img.file_name)
    //     })
    // }

    //This will download the images, intraop, botox that are selected
    const handleDownloadAll = () => {
        let selectedImages = getSelectedImagesList(combinedState)
        // console.log("SELECTED IMAGES", selectedImages)

        selectedImages.map(img => {
            const url=selectImageDownloadURL(img.file_name, props.patient_id, img)
            downloadURI(url,img.file_name)
        })
    }



    const handleClose = () => {
        props.onHide()
    }

    //Sergey's code, please review and remove as needed
    // const onImageClick = (event, image) => {
    //     // toggle selection 
    //     let images = imgArray
    //     images = images.map(irow => {
    //         return irow.map(i => {
    //             if (i.id === image.id) {
    //                 return {...i, selected: !i.selected}
    //             } else {
    //                 return i
    //             }
                
    //         })
    //     })
    //     // console.log("IMAGES AFTER SELECTION", image, images)
    //     setImgArray([...images])

    //     // enable/disable buttons
    //     let selectedImages = getSelectedImagesList(images)

    //     if (selectedImages.length > 0) {
    //         // enable buttons
    //         setEnableButtons(true)
    //     } else {
    //         // disable buttons
    //         setEnableButtons(false)
    //     }

    // }

    //This is for the selection of images, botox, intraop in the panel, the buttons be disabled if non are chosen
    const onImageClickAll = (event, image) => {
        // toggle selection 
        let images = combinedState
        images = images.map(irow => {
            return irow.map(i => {
                if (i.id === image.id) {
                    return {...i, selected: !i.selected}
                } else {
                    return i
                }
                
            })
        })
        // console.log("IMAGES AFTER SELECTION", image, images)
        setCombinedState([...images])

        // enable/disable buttons
        let selectedImages = getSelectedImagesList(images)

        if (selectedImages.length > 0) {
            // enable buttons
            setEnableButtons(true)
        } else {
            // disable buttons
            setEnableButtons(false)
        }

    }


    // check it here: https://stackoverflow.com/questions/70949809/download-image-from-url-on-page-load-using-javascript
    const downloadURI = (url, name) => {
        fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
            // the filename you want
            a.download = name;
            // document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((e) => alert('An error sorry ' + e));
    }


    return (
        <>
        <Modal size="fullscreen" show={props.show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Container>
                    <div className='inline-block text-left'>
                        {/* <Button className='mx-2' variant="primary" onClick={handlePrint} disabled={!enableButtons}> */}
                        <Button className='mx-2' variant="primary" onClick={handlePrintAll} disabled={!enableButtons}>
                            Print
                        </Button>
                    </div>
                    <div className='inline-block text-left'>
                        {/* <Button className='mx-2' variant="primary" onClick={handlePowerpoint} disabled={!enableButtons}> */}
                        <Button className='mx-2' variant="primary" onClick={handlePowerpointAll} disabled={!enableButtons}>
                            Create Powerpoint
                        </Button>
                    </div>
                    <div className='inline-block text-left'>
                        {/* <Button className='mx-2' variant="primary" onClick={handleDownload} disabled={!enableButtons}> */}
                        <Button className='mx-2' variant="primary" onClick={handleDownloadAll} disabled={!enableButtons}>
                            Download Images
                        </Button>
                    </div>
                </Container>
                
            </Modal.Header>

            <Modal.Body>
                {/* Sergey please delete if not needed for you anymore */}
                {/* <div style={{overflowY: 'auto'}}>
                <Container fluid >
                {imgArray.length > 0 && 
                    imgArray.map((img_row, row_ix) => {
                            return (
                                <Row key={row_ix} >
                                    {img_row.map((img, img_ix) => {
                                        return (
                                            <Col className="text-center" xs={12} md={12/columnCount} key={img.id} >
                                                <img className={`enlighten-panel-image ${img.selected ? 'enlighten-panel-image-selected' : ''}`}
                                                    src={imageURL(img.file_name, props.patient_id)}
                                                    
                                                    // src='https://enlightenmdssapi.meei.harvard.edu/api/v1/file_uploads/image/7a014c57-f37d-466c-801e-a68163950ee2.JPG?patient_id=21'
                                                    onClick={(event) => onImageClick(event,img) }
                                                    id={`img_${img.id}`}
                                                />
                                                <div>{new Date(img.updated_at).toLocaleString('en-US')}</div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            )
                        })
                }
                </Container>
                </div> */}

            <div style={{overflowY: 'auto'}}>
                <Container fluid >
                {combinedState.length > 0 && 
                    combinedState.map((img_row, row_ix) => {
                            return (
                                <Row key={row_ix} >
                                    {img_row.map((img, img_ix) => {
                                        return (
                                            <Col className="text-center" xs={12} md={12/columnCount} key={img.id} >
                                                <img className={`enlighten-panel-image ${img.selected ? 'enlighten-panel-image-selected' : ''}`}
                                                    //src={botoxImageURL(img.file_name, props.patient_id)}
                                                    src={selectImageURL(img.file_name, props.patient_id, img)}
                                                    onClick={(event) => onImageClickAll(event,img) }
                                                    id={`img_${img.id}`}
                                                />
                                                <div>{new Date(img.updated_at).toLocaleString('en-US')}</div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            )
                        })
                }
                </Container>
                </div>


            </Modal.Body>

            <Modal.Footer className='justify-content-between'>
                <div className="inline-block text-left"> 
                    <Button className='mx-2' variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
        
        </>
    )
}

export default EnlightenPanel
