import React, {useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from "react";
import DOMPurify from 'dompurify';
import "../../stylesheets/BotoxMap.css";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from 'fabric';
import Container from 'react-bootstrap/Container';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import BotoxType from "../dropdowns/BotoxType";
import BotoxMapImage from "../dropdowns/BotoxMapImage";
import DictBotoxSrcImageUpload from "../fileuploads/DictBotoxSrcImageUpload";
import ColorBox from "../colorboxes/ColorBox";
import userPrefContext from "../../../context/userPrefContext";
import PatientContext from "../../../context/patientContext";
import DictionaryContext from "../../../context/dictionaryContext";
import AuthContext from "../../../context/authContext";
import CompanyContext from "../../../context/companyContext";
// import VisitWithPrefContext from "../../../context/visitWithPrefContext";
import pptxgen from 'pptxgenjs'
import { forEach } from "lodash";
import { BsLayoutThreeColumns } from "react-icons/bs";
  
  
//this is setting the picture to the one in the public folder for now
//let picture = 'https://images.unsplash.com/photo-1676107779594-7a23bd99e07c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
const canvasWidth = '1337'
const canvasHeight = '664'

let DEFAULT_IMAGE = "";
// const publicImage = "botoxmap.png";
const publicImage = "botox-map-full.png";
  
  
const BotoxMap2 = forwardRef((props, ref) => {
  
    const userPrefCtx = useContext(userPrefContext);
    const patCtx = useContext(PatientContext);
    const dictCtx = useContext(DictionaryContext);
    const authCtx = useContext(AuthContext)
    const companyCtx = useContext(CompanyContext)

    const defaultImageFromDict = userPrefCtx.dictBotoxMapSrcImage?.file_name;

    let scale = 1;

    if(defaultImageFromDict === null || defaultImageFromDict === undefined){

        DEFAULT_IMAGE = publicImage;
        // console.log('defaultImageFromDict is null')
    } else {
        DEFAULT_IMAGE = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${defaultImageFromDict}?dict_company_code_id=${companyCtx.company.id}`
        console.log('defaultImageFromDict is NOT null')
    }
  
    const [saveBotoxID, setSaveBotoxID] = useState(); 
    const [botoxTypeSelect, setBotoxTypeSelect] = useState();
    const [botoxImageSelect, setBotoxImageSelect] = useState(DEFAULT_IMAGE);
  
    const noteRef = useRef(null);
    const canvas_wrapper = useRef();
  
    const { editor, onReady } = useFabricJSEditor();
  
    const [radioValue, setRadioValue] = useState('botox')
    const radios = [
        { name: 'Botox', value: 'botox' },
        { name: 'Freehand', value: 'freehand' },
        { name: 'Off', value: 'off' },
    ]



    const canvasSizeCalculations = (img) => {
        // calculate scale to fit

        console.log("canvasSizeCalculations - props.size", props.size)

        let canvasW = 0
        let canvasH = 0
        let scale = 1.0
        let size = props.size
        if (size.width === 0 && size.height === 0) {
            size = props.calculateAvailableSize()
        }

console.log("calculated available size", size)
console.log("image size", img.width, img.height)

        if (size.width > 0 && size.height > 0) {
            // props.size is the size available for this component (Botox Map)
            // substract height of buttons
            const buttons = document.getElementsByClassName('buttons_below_canvas')[0]

                const buttonsRect = buttons.getBoundingClientRect()
                canvasH = size.height - buttonsRect.height - 30
                canvasW = size.width - 30

                // calculate image sizes ratio
                const img_ratio = (img.width/img.height)

                if (canvasW/canvasH > img_ratio) {
                    canvasW = canvasH * img_ratio
                } else {
                    canvasH = canvasW/img_ratio
                }

                const scaleY = canvasH / img.height
                const scaleX = canvasW / img.width
                scale = Math.min(scaleY, scaleX, 1.0)
                // console.log("scale", scale)

                // change size of the canvas
                canvasW = img.width * scale
                canvasH = img.height * scale

                

        }

        console.log("canvasSizeCalculations - return", {width: canvasW, height: canvasH, scale: scale})

        return {width: canvasW, height: canvasH, scale: scale}
    }


    // code is taken from here https://stackoverflow.com/questions/11442712/get-width-height-of-remote-image-from-url
    // my original approach is not good because by the time I was trying to measure the image it was not downloaded yet
    const getImageMeta = async (url) => {
        const img = new Image();
        img.crossOrigin = '*';
        img.src = url;
        await img.decode();  
        return img
    };
  
    const _onReady = (canvas) => {
        //This function will provide the initial setup for the fabric canvas

        let textDate = `Date: ${new Intl.DateTimeFormat("en-US", {year: 'numeric', month: '2-digit', day: '2-digit'}).format(new Date())}`
        var dateid = 1;
        if (props.sendEditBotoxMapData.botox_map_image_data != null) {
            let data = JSON.parse(props.sendEditBotoxMapData.botox_map_image_data)

            // console.log("data from botox", data)

            getImageMeta(data.backgroundImage.src).then((img) => {
                const canvasSize = canvasSizeCalculations(img)
                // delete image from memory
                img = null
                canvas.setWidth(canvasSize.width)
                canvas.setHeight(canvasSize.height)
                canvas.setZoom(canvasSize.scale)

                // console.log("calculated canvasW, canvasH", canvasSize.width, canvasSize.height)

                canvas.loadFromJSON(props.sendEditBotoxMapData.botox_map_image_data, function () {  
    
                    //The color scales on the left side will be removed when it is initially loaded
                    canvas.getObjects().forEach(function (obj) {
                        //console.log(obj.type);
                        if (obj.type === "group") {
                            canvas.remove(obj);
                        }
                        if ((obj.type === "i-text" || obj.type === "text") && obj.text.startsWith("Date")) {
                            canvas.remove(obj);
                            const dateText = new fabric.IText(textDate, {
                                left: 1175,
                                top: 0,
                                name: "texting",
                                id: dateid,
                                fontSize: 20,
                            });
                            canvas.add(dateText);
                        }
                    });
    
                    canvas.renderAll();
                    onReady(canvas);
                })

            })

            
        } else {
            const dateText = new fabric.IText(textDate, {
                left: 1175,
                top: 0,
                name: "texting",
                id: dateid,
                fontSize: 20,
            });

            canvas.add(dateText);
            canvas.setWidth(600)
            canvas.setHeight(400)

            onReady(canvas);
        }
    };
  
    // setting a default color before it gets changed
    let colorCircle = "";
    const varcolor = document.getElementById("colorpicker");
    const getCircleColor = () => {
        if (varcolor) {
            varcolor.addEventListener("click", function (e) {
                let rgbcol = e.target.style.backgroundColor;
                if (!!rgbcol) {
                    let brush = editor.canvas.freeDrawingBrush
                    brush.color = rgbcol

                }
    
                colorCircle = getBgColorHex(rgbcol);
            });
            return colorCircle;
        } else {
            console.log("colorpicker doesnt exist yet");
        }
    };
  
    let lastCirclePos = null;
    let drawnPositions = [];
  
    const drawCircle = (o) => {
        let colfunc = getCircleColor();
        const { offsetX, offsetY } = o.e;
        const currentPos = { x: offsetX, y: offsetY };
  
  
        var pointer = editor.canvas.getPointer(o.e);
  
        const existingIndex = drawnPositions.findIndex((pos) => {
            return pos.x === pointer.x && pos.y === pointer.y;
        });
  
        const existingPosition = drawnPositions.some((pos) => {
            return pos.x === pointer.x && pos.y === pointer.y;
        });
  
        const existingCircle = editor.canvas.getObjects().find((obj) => {
            return obj.type === "circle" && obj.left === pointer.x && obj.top === pointer.y;
        });
  
 
        if (!existingCircle) {    

            var circle = new fabric.Circle({
                left: pointer.x,
                top: pointer.y,
                radius: 7,
                fill: colfunc,
                hasRotatingPoint: true,
                originX: "center",
                originY: "center",
            });
  
  
        if (colfunc != "") {
            //This function will make sure that extra blank circles dont appear
            editor.canvas.add(circle);
        }
            //This will keep track of the positions so extra circles don't get added when there is a click
            drawnPositions.push({ left: pointer.x, top: pointer.y });
            lastCirclePos = pointer
        }
    };
  
    function deleteAllCircles() {
        // Get the canvas instance
        if (typeof editor !== 'undefined') {
            // Canvas object exists
            // Do something with the canvas object
            //console.log(editor);
            const circles = editor.canvas.getObjects().filter(obj => obj.type === 'circle');
        
            // Remove each circle object from the canvas
            circles.forEach(circle => editor.canvas.remove(circle));
            
            // Update the canvas display
            editor.canvas.renderAll();
        } else {
            // Canvas object does not exist
            //console.log("Canvas object does not exist.");
        }
    }
  
    useEffect(() => {
        if (editor) {
            //This will only allow drawing to happen if the the text says to exit drawing mode
            if(radioValue === 'botox'){
                getCircleColor();
                editor.canvas.on("mouse:down", drawCircle);
            }
    
            //This function is to read double clicks
            editor.canvas.on('mouse:dblclick', function(e) {
                //console.log(e.target)
                if(e.target != null) {
                    if (e.target?.type === 'circle') {
                        //console.log('The clicked object was a circle')
                        onDeleteSelected();
                    } else if (e.target.type === 'text') {
                        //console.log('The clicked object was a text')
                    }
                }
            })
        }
    }, [editor]);
  

    const innerDimensions = (node) => {
        var computedStyle = getComputedStyle(node)
        console.log("Box Sizing", computedStyle.boxSizing)
        let width = node.clientWidth // width with padding
        let height = node.clientHeight // height with padding
      
        height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom) + parseFloat(computedStyle.borderTop) + parseFloat(computedStyle.borderBottom)

        console.log("Padding Bootom", parseFloat(computedStyle.paddingBottom))
        width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight) + parseFloat(computedStyle.borderLeft) + parseFloat(computedStyle.borderRight)
        return { height, width }
    }


    

    useEffect(() => {
        //This useEffect is to change the background image based on the dropdown
        if (editor) {
            if (props.sendEditBotoxMapData.botox_map_image_data != null || props.sendEditBotoxMapData.botox_map_image_data === []) {
                //do nothing, because it's loaded from the onReady function and doesn't need logic to load from useEffect

                console.log("Botox Map 2 - Have data. props=", props)
        
            } else {
                //If it's not set to edit it will load from url
                //This is essentially going to set the botox map image to default if undefined is chosen
                let imageChangeVar = props.sendImageChange;
        
                // Extract the segment between "/dict_botox_map_src_image/" and "?"
                var startIndex = imageChangeVar.indexOf('/dict_botox_map_src_image/') + '/dict_botox_map_src_image/'.length;
                var endIndex = imageChangeVar.indexOf('?');
                var segment = imageChangeVar.substring(startIndex, endIndex);
    
                // Check if the segment is equal to "undefined"
                // var isUndefined = segment === 'undefined';
                if(segment === 'undefined'){
                    imageChangeVar = publicImage
                }
  

                // console.log("BottoxMap2 imageChangeVar", imageChangeVar)

                 new fabric.Image.fromURL(imageChangeVar, function (img) {
                    const botox_map_div = canvas_wrapper.current.parentElement

                    img.set({
                        setWidth: 1337,
                        setHeight: 663,
                    });

                    // calculate scale to fit
                    let canvasW = 0
                    let canvasH = 0
                    if (props.size.width > 0 && props.size.height > 0) {
                        // props.size is the size available for this component (Botox Map)
                        // substract height of buttons
                        const buttons = document.getElementsByClassName('buttons_below_canvas')[0]

                            const buttonsRect = buttons.getBoundingClientRect()
                            canvasH = props.size.height - buttonsRect.height - 30
                            canvasW = props.size.width - 30

                            // calculate image sizes ratio
                            const img_ratio = (img.width/img.height)

                            if (canvasW/canvasH > img_ratio) {
                                canvasW = canvasH * img_ratio
                            } else {
                                canvasH = canvasW/img_ratio
                            }

                            const scaleY = canvasH / img.height
                            const scaleX = canvasW / img.width
                            scale = Math.min(scaleY, scaleX, 1.0)
                            // console.log("scale", scale)

                            // change size of the canvas
                            canvasW = img.width * scale
                            canvasH = img.height * scale
                            editor.canvas.setWidth(canvasW)
                            editor.canvas.setHeight(canvasH)

 
        
        
                            editor.canvas.setZoom(scale)
                            editor.canvas.renderAll();

                    }




                    



                    editor.canvas.setBackgroundImage(img, editor.canvas.renderAll.bind(editor.canvas), {
                        top: 0,
                        left: 0,
                        originX: 'left',
                        originY: 'top',
                    })


                    editor.canvas.setZoom(scale)

                    editor.canvas.renderAll();
                }, {crossOrigin: "*"});
            }
        }
    }, [editor, botoxImageSelect, props.sendImageChange]);
  



    useEffect(() => {
        //console.log('this is the 3rd useeffect, should only run when there is a change in props.sendImageChange')
        deleteAllCircles()
    }, [props.sendImageChange]);
  
  


    useEffect(() => {
        const nameString = "Name: " + patCtx.pat_first + ' ' + patCtx.pat_last;
    
        if(editor) {
            const checkForName = editor.canvas.getObjects().filter(obj => obj.text?.includes("Name"));
            
            // Remove each circle object from the canvas
            checkForName.forEach(circle => editor.canvas.remove(circle));       
            const dateText = new fabric.IText(nameString, {
                left: 0,
                top: 0,
                name: "texting",
                fontSize: 20,
            });

            if(props.sendAddNameCheck === true) {
                editor.canvas.add(dateText);
            } else if (props.sendAddNameCheck === false) {
                editor.canvas.remove(dateText);
            }
        }
    
    }, [editor, props.sendAddNameCheck]);
  
  
    const onDeleteSelected = () => {
        editor.deleteSelected();
    };
  


    /* This is to select the color */
    function getBgColorHex(elem) {
        if (elem) {
            var color = elem;
            var hex;
            if (color.indexOf("#") > -1) {
                //for IE
                hex = color;
            } else {
                var rgb = color.match(/\d+/g);
                hex =
                    "#" +
                    ("0" + parseInt(rgb[0], 10).toString(16)).slice(-2) +
                    ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
                    ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2);
            }
            return hex;
        }
    }
  
    if (props.sendEditBotoxMapData != null) {
        const jsonData = props.sendEditBotoxMapData.botox_map_image_data;
    }
  

    const saveBotox = async (e) => { 
        const SaveBotoxServer = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/${patCtx.pat_token}?dict_company_code_id=${companyCtx.company.id}`;

        if (props.colorScaleIncluded) {
            groupColorValues();
        }

        let canvasJSON = JSON.stringify(editor.canvas.toJSON());
  
        const black = document.getElementById("010101").value;
        const red = document.getElementById("DC143C").value;
        const purple = document.getElementById("6F339D").value;
        const green = document.getElementById("5BFF54").value;
        const blue = document.getElementById("2A84FF").value;
        const yellow = document.getElementById("FFFF00").value;
        const pink = document.getElementById("FC00FF").value;
        const lightpink = document.getElementById("FFCCFF").value;
  
        var selectBotoxType = document.getElementById("botox-type-select");
  
        var botoxTypeText = selectBotoxType.options[selectBotoxType.selectedIndex].text;
        let noteValue = props.sendNoteRef.current.value;
  
        // If the botox map is fresh then it will send the botoxmapimage
        var selectBotoxMapImage = "";
  
        if (props.sendEditBotoxMapData.botox_map_image_data != null) {
            //this is to set the id to the props if its edit, and checking if the length is 1
            if(props.sendBotoxTypeID.length === 1){
                selectBotoxMapImage = props.sendBotoxTypeID[0];
            }
        } else {
            selectBotoxMapImage = document.getElementById("botox-image-select").value;
        }
  
        var canvasImg = editor.canvas.toDataURL("image/png");
        // canvasImg = temp_canvas.toDataURL("image/png")
        canvasImg = editor.canvas.toDataURL({
            format:"image/png",
            multiplier: 1/scale
        });
    
        const turnToInteger = parseInt(selectBotoxMapImage, 10);
    
        const findFile = dictCtx.dict_botox_map_src_image?.find(obj => obj.id === turnToInteger);
        console.log("Botox Map2 turnToInteger", turnToInteger)
        console.log("Botox Map2 dictCtx.dict_botox_map_src_image", dictCtx.dict_botox_map_src_image, "findFile", findFile)
    
        let sendFileName = findFile?.orig_file_name;
        if(sendFileName === undefined){
            sendFileName = 'botox-map-full.png'
        }
  
        console.log("Botox Map2 sendFileName", sendFileName)

        const responseData = await fetch(SaveBotoxServer, {
            method: "POST",
            body: JSON.stringify({
                "botox_map_image_data": `${canvasJSON}`,
                "botox_map_type": `${botoxTypeText}`,
                //"botox_map_pen_color_navy_blue": `${black}`,
                "botox_map_pen_color_black": `${black}`,
                "botox_map_pen_color_red": `${red}`,
                "botox_map_pen_color_purple": `${purple}`,
                "botox_map_pen_color_green": `${green}`,
                "botox_map_pen_color_blue": `${blue}`,
                "botox_map_pen_color_yellow": `${yellow}`,
                "botox_map_pen_color_pink": `${pink}`,
                "botox_map_pen_color_lightpink": `${lightpink}`,
                "botox_map_other_desc": `${noteValue}`,
                //"file_name": `${fileStringTest}`,
                //"file_name": '.png',
                "file_name": `${sendFileName}`,
                "dict_botox_map_src_image_id": selectBotoxMapImage,
                "botox_map_image_base64": `${canvasImg}`
            }),
            headers: {
                // "Content-Type": "application/x-www-form-urlencoded",
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                auth: authCtx.authMode,
            },
        })
        //If there is an error, the dialog will show up, it's called outside of this component because of forward refs
        //.then((res) => res.json())
        .then((res) => {
            if (!res.ok) {
                throw new Error('Unprocessable Entity: ' + res.statusText);
            }
            return res.json();
        })
        .then((data) => {
            //console.log(data);
            // console.log(
            //   "botox_map_json_image_id is ",
            //   data.botox_map_json_image_id
            // );
            setSaveBotoxID(data.botox_map_json_image_id);

            //This is to send data to the parent
            //props.setChild(data.botox_map_json_image_id);
            props.setChild(data);
        })
        .catch((err) => {
            console.log(err.message);
            props.openErrorFromChild();
        });
  
    };
  

    if (saveBotoxID != null) {
        console.log("saveBotoxID is not null: ", saveBotoxID);
    }
  
    const pull_data = (data) => {
        //This is the function to get the color value from the BotoxType(child) component
        //This will need to be sent to the the dropdown/colorpicker boxes
        //console.log(data);
        setBotoxTypeSelect(data);
        // setColorArr(data);
    };
  
  
    const getBotoxImageFromChild = (data) => {
        //This is to get data from the BotoxMapImage component to change the 
        console.log(data);
    
        const srcProp = userPrefCtx.dictBotoxMapSrcImage;
        console.log(userPrefCtx?.dictBotoxMapSrcImage);
        let base64Data = []
        let fileNameArray = []
    
        for(let i = 0; i < srcProp.length; i++){
            if(srcProp[i].id.toString() === data){
                fileNameArray.push(srcProp[i].file_name)
            } else {
                //console.log(srcProp[i].id+ ' doesnt equal ' + data)
            }
        }
  
        console.log(fileNameArray[0]);
  
        const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/botox_maps/dict_botox_map_src_image/${fileNameArray[0]}?dict_company_code_id=${companyCtx.company.id}`
        setBotoxImageSelect(imageURL);

    }
  

    const checkRef = (data) => {
        //This is to check the ref for drawing mode, needs further implementation
        console.log("noteRef is ", noteRef.current.value);
    
        let noteValue = noteRef.current.value;
    
        console.log("noteValue is ", noteValue);
    };


  
    const exportImage = (data) => {
        //This will add the color values to the left side
        if (props.colorScaleIncluded) {
            groupColorValues();
        }
      
  
        let imgUrl = editor.canvas.toDataURL({
            format: "png",
            quality: 0.8,
        });
      
        const url = imgUrl;
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = DOMPurify.sanitize(url, {SAFE_FOR_JQUERY:true})
                // the filename you want
                a.download = 'botoxmaptest.png';
                // document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
        };
    
        const exportPPT = (data) => {
  
        }
  
        const groupColorValues = (e) => {
            var circle_010101 = "#010101";
            var circle_DC143C = "#DC143C";
            var circle_6F339D = "#6F339D";
            var circle_5BFF54 = "#5BFF54";
            var circle_2A84FF = "#2A84FF";
            var circle_FFFF00 = "#FFFF00";
            var circle_FC00FF = "#FC00FF";
            var circle_FFCCFF = "#FFCCFF";
        
            const blackVal = document.getElementById("010101").value;
            const redVal = document.getElementById("DC143C").value;
            const purpleVal = document.getElementById("6F339D").value;
            const greenVal = document.getElementById("5BFF54").value;
            const blueVal = document.getElementById("2A84FF").value;
            const yellowVal = document.getElementById("FFFF00").value;
            const pinkVal = document.getElementById("FC00FF").value;
            const lightpinkVal = document.getElementById("FFCCFF").value;
  
            circle_010101 = new fabric.Circle({
                left: 10,
                top: 58,
                radius: 3,
                fill: circle_010101,
                selectable: true,
                originX: "center",
                originY: "center",
            });
            circle_DC143C = new fabric.Circle({
                left: 10,
                top: 74,
                radius: 3,
                fill: circle_DC143C,
                selectable: true,
                originX: "center",
                originY: "center",
            });
            circle_6F339D = new fabric.Circle({
                left: 10,
                top: 90,
                radius: 3,
                fill: circle_6F339D,
                selectable: true,
                originX: "center",
                originY: "center",
            });
            circle_5BFF54 = new fabric.Circle({
                left: 10,
                top: 106,
                radius: 3,
                fill: circle_5BFF54,
                selectable: true,
                originX: "center",
                originY: "center",
            });
            circle_2A84FF = new fabric.Circle({
                left: 10,
                top: 122,
                radius: 3,
                fill: circle_2A84FF,
                selectable: true,
                originX: "center",
                originY: "center",
            });
            circle_FFFF00 = new fabric.Circle({
                left: 10,
                top: 138,
                radius: 3,
                fill: circle_FFFF00,
                selectable: true,
                originX: "center",
                originY: "center",
            });
            circle_FC00FF = new fabric.Circle({
                left: 10,
                top: 154,
                radius: 3,
                fill: circle_FC00FF,
                selectable: true,
                originX: "center",
                originY: "center",
            });
            circle_FFCCFF = new fabric.Circle({
                left: 10,
                top: 170,
                radius: 3,
                fill: circle_FFCCFF,
                selectable: true,
                originX: "center",
                originY: "center",
            });
  
            //var nameText = "Name: " + patCtx.pat_first + ' ' + patCtx.pat_last + "\n";
            var nameText = new fabric.IText("Name: " + patCtx.pat_first + ' ' + patCtx.pat_last, {
            // var nameText = new fabric.IText(patCtx.pat_first + ' ' + patCtx.pat_last, {
            left: 7,
            top: 35, // Adjust the top position as needed
            fontSize: 12, // Adjust the font size as needed
            });
  
            var textDate =
                "Black: " +
                blackVal +
                "\n" +
                "Red: " +
                redVal +
                "\n" +
                "Purple: " +
                purpleVal +
                "\n" +
                "Green: " +
                greenVal +
                "\n" +
                "Blue: " +
                blueVal +
                "\n" +
                "Yellow: " +
                yellowVal +
                "\n" +
                "Pink: " +
                pinkVal +
                "\n" +
                "LightPink: " +
                lightpinkVal;
  
            let myid = 2;
        
            var text = new fabric.IText(textDate, {
                left: 20,
                top: 50,
                name: "texting",
                id: myid,
                fontSize: 12,
            });
  
        var group = new fabric.Group(
            [
                circle_010101,
                circle_DC143C,
                circle_6F339D,
                circle_5BFF54,
                circle_2A84FF,
                circle_FFFF00,
                circle_FC00FF,
                circle_FFCCFF,
                text,
            ],
            {
                left: 10,
                top: 50,
                id: "group_circle_text",
            }
        );
  
        editor.canvas.add(group);
    };
  
  
    const canvasHistory = [];
  
    const undo = () => {
        if (editor.canvas._objects.length > 0) {
            //This will take the object out of the canvas and push it to the array
            canvasHistory.push(editor.canvas._objects.pop());
        }
        editor.canvas.renderAll();
    };
  
    const redo = () => {
        if (canvasHistory.length > 0) {
            //This will take the object out of the array and put it in the canvas
            editor.canvas.add(canvasHistory.pop());
        }
    };
  
    const drawingModeChanged = (e) => {
        setRadioValue(e.currentTarget.value)
  
        switch (e.currentTarget.value) {
            case 'botox':
                editor.canvas.selection = false
                editor.canvas.on('mouse:down');
                editor.canvas.on('mouse:move');
                editor.canvas.on('mouse:up');
                // editor.canvas.forEachObject(function(o) {
                //     o.setCoords()
                // })
                editor.canvas.isDrawingMode = false
                break

            case 'freehand':
                editor.canvas.selection = false
                editor.canvas.off('mouse:down');
                editor.canvas.off('mouse:move');
                editor.canvas.off('mouse:up');

                editor.canvas.isDrawingMode = true
                editor.canvas.freeDrawingBrush = new fabric.PencilBrush(editor.canvas)
                let brush = editor.canvas.freeDrawingBrush
                brush.color = getCircleColor();
                brush.width = 3;
                break

            case 'off':
                editor.canvas.selection = true
                editor.canvas.off('mouse:down');
                editor.canvas.off('mouse:move');
                editor.canvas.off('mouse:up');
                editor.canvas.forEachObject(function(o) {
                    o.setCoords()
                })
                editor.canvas.isDrawingMode = false
                break
        }
          
    } 
  
  
    const childFunction = () => {
        //This is a function that will be called by useImperativeHandle and will then call saveBotox
        saveBotox();
    };
  
    const childFunctionBotoxImage = () => {
        //This is a function that will be called by useImperativeHandle and will then call saveBotox
        console.log('Child function called!');
        getBotoxImageFromChild()
    };
  
    useImperativeHandle(ref, () => ({
        //This is to pass the saveBotox function to the parent component
        childFunction
    }));
  
    return (
        <div className='h-100 row'>
            <div className='col-12'>
                <div ref={canvas_wrapper} className="prime_canvas_wrapper">
                    <div className="canvas_wrapper">
                        <FabricJSCanvas 
                            id='botox-canvas'
                            onReady={_onReady} 
                            // onReady={onReady}
                            sendProps={botoxImageSelect}
                            backgroundImage={botoxImageSelect} 
                        />
                    </div>
                </div>

  
                <div className="buttons_below_canvas">
                    <div className="container">
                        <div className="botox_action_buttons_styling">
                            <div>
                                <div>
                                    <ButtonGroup >
                                        {radios.map((radio, idx) => (
                                            <ToggleButton
                                                key={idx}
                                                id={`radio-${idx}`}
                                                type="radio"
                                                // variant="secondary"
                                                name="radio"
                                                value={radio.value}
                                                checked={radioValue === radio.value}
                                                onChange={drawingModeChanged}
                                                className="btn btn-primary"
                                            >
                                                {radio.name}
                                            </ToggleButton>
                                        ))}
                                    </ButtonGroup>
                                </div>
                            </div>
            
                            <div>
                                <div>
                                    <Button 
                                        id="undo-button"
                                        className={`btn btn-primary`}
                                        onClick={() => undo()}
                                    >
                                        Undo
                                    </Button>
                                </div>
                            </div>
    
                            <div>
                                <div>
                                    <Button 
                                        id="redo-button"
                                        className={`btn btn-primary`}
                                        onClick={redo}
                                    >
                                        Redo
                                    </Button>
                                </div>
                            </div>
            
                            <div>
                                <div>
                                    <Button 
                                        id="delete-button"
                                        className={`btn btn-primary`}
                                        onClick={onDeleteSelected}
                                    >
                                        Delete selected
                                    </Button>
                                </div>
                            </div>
    
                            <div>
                                <div>
                                    <Button 
                                        id="export-button"
                                        className={`btn btn-primary`}
                                        onClick={exportImage}
                                    >
                                        Export JPEG
                                    </Button>
                                </div>
                            </div>
    
    
                            {props.sendEditBotoxMapData.botox_map_image_data === undefined && (
                                <div>
                                        <DictBotoxSrcImageUpload 
                                            visitTrigger={props.visitTrigger}
                                        />
                                </div>
                            )}
    
                        </div>
                    </div>
                </div>   
            </div>       
        </div>
    );
  });
  
  export default BotoxMap2;
  