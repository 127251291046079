import React, {useState, useContext, useRef, useEffect, useMemo, useCallback} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import CompanyContext from '../../../context/companyContext';
import PatientContext from '../../../context/patientContext';
import UserPrefContext from '../../../context/userPrefContext';
import AuthContext from '../../../context/authContext'
import EditImageToolbarHorizontal from '../shared/EditImageToolbarHorizontal';
import {MdNotes} from 'react-icons/md'
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { IconContext } from "react-icons";
import {BsFillExclamationTriangleFill} from 'react-icons/bs'
import {Form, Row, Col} from 'react-bootstrap'
import ImageEditFabric from '../fabric/ImageEditFabric';
import './EditImages.css'
import ConfirmDialog from '../shared/ConfirmDialog';



function EditImages(props) {

    const companyCtx = useContext(CompanyContext)
    const patientCtx = useContext(PatientContext)
    const userPrefCtx = useContext(UserPrefContext)
    const authCtx = useContext(AuthContext)

    const refBody = useRef()
    const refCurrent = useRef()
    const refCount = useRef()
    const refOverlay = useRef()


    const [showConfirm, setShowConfirm] = useState(false)
    const confirmDialogText = 'Are you sure you want to close this window? All changes you made will be lost!'
    const [brushColor, setBrushColor] = useState('#000000')
    const [brushSize, setBrushSize] = useState(2)
    const [mode, setMode] = useState('draw')
    const [imageIndex, setImageIndex] = useState(0)
    const [imageCount, setImageCount] = useState(1)
    const [note, setNote] = useState('')
    const [bodySize, setBodySize] = useState({width: 0, height: 0})

    // const [images, setImages] = useState(props.images?.map(img => null) || [])
    const [images, setImages] = useState([])

    const [showNotes, setShowNotes] = useState(false)
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [zoomScale, setZoomScale] = useState(1.0)

    // const [drawingModes, setDrawingModes] = useState(props.images?.map(() => true) || [])
    // const [brushColors, setBrushColors] = useState(props.images?.map(() => '#000000') || [])
    // const [brushSizes, setBrushSizes] = useState(props.images?.map(() => 2) || [])
    const [modes, setModes] = useState(props.images?.map(() => 'draw') || [])
    const [zoomScales, setZoomScales] = useState(props.images?.map(() => 1.0) || [])

    // const [deleteTimes, setDeleteTimes] = useState(props.images?.map(() => null) || [])
    // const [undoTimes, setUndoTimes] = useState(props.images?.map(() => null) || [])
    // const [redoTimes, setRedoTimes] = useState(props.images?.map(() => null) || [])

    // const [imageData, setImageData] = useState(props.images?.map(() => null) || [])

    const [canvasHistory, setCanvasHistory] = useState([]);


    const editors = props.images?.map(() => null || [])


    const setPageEditor = useCallback((editor, index) => {
        editors[index] = editor
    })

    const closeConfirmDialog = (result) => {
        if (result === 'confirm') {
            props.hide()
        } else {
            setShowConfirm(false)
        }
    }


    const clickOverlay = () => {
        closePopup()
    }




    


    const showOverlay = (show) => {
        // console.log("Show Overlay - show", show)
        if (show) {
            // make overlay active
            const body = refBody.current.getBoundingClientRect()
            // console.log("body rect", body)
            // console.log("refOverlay", refOverlay.current)
            refOverlay.current.style.position = 'fixed'
            refOverlay.current.style.zIndex = 10
            refOverlay.current.style.top = `${body.top}px`
            refOverlay.current.style.left = `${body.left}px`
            refOverlay.current.style.width = `${body.width}px`
            refOverlay.current.style.height = `${body.height}px`
            refOverlay.current.style.background = 'rgba(255,255,255,0.5)'
            refOverlay.current.style.display = 'block'

            refOverlay.current.addEventListener("click", clickOverlay, true)
        } else {
            // remove overlay
            refOverlay.current.removeEventListener("click", clickOverlay, true)
            refOverlay.current.style.zIndex = -1000
            refOverlay.current.style.position = 'fixed'
            refOverlay.current.style.top = '0px'
            refOverlay.current.style.left = '0px'
            refOverlay.current.style.width = '0px'
            refOverlay.current.style.height = '0px'
            refOverlay.current.style.background = 'transparent'
            refOverlay.current.style.display = 'block'

            
        }
    }



    const closePopup = () => {
        setShowNotes(false)
        showHideColorpicker(false)
    }




    const showHideNotes = () => {
        showOverlay(!showNotes)
        setShowNotes(!showNotes)
    }



    const showHideColorpicker = (show) => {
        showOverlay(show)
        setShowColorPicker(show)
    }

    const noteChanged = (e) => {
        setNote(e.target.value)
    }

    const innerDimensions = (node) => {
        let width = 0.0
        let height = 0.0
        if (!!node) {
            var computedStyle = getComputedStyle(node)
            // console.log("Box Sizing", computedStyle.boxSizing)
            let width = node.clientWidth // width with padding
            let height = node.clientHeight // height with padding
          
            height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom) + parseFloat(computedStyle.borderTop) + parseFloat(computedStyle.borderBottom)
    
            // console.log("Padding Bootom", parseFloat(computedStyle.paddingBottom))
            width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight) + parseFloat(computedStyle.borderLeft) + parseFloat(computedStyle.borderRight)
            return { height, width }
        } 
        
        return { height, width }
        
        
    }


    const bodyDimentions = useMemo(() => innerDimensions(refBody.current), [refBody.current])



    const onDeleteSelected = () => {
        const selected = editors[imageIndex]?.canvas?.getActiveObject()
        if (!!selected) {
            editors[imageIndex].canvas.remove(selected)
            editors[imageIndex].deleteSelected();
        }
    }
    

    const undo = () => {
        // console.log("History before Undo", canvasHistory)
    
        if (modes[imageIndex] === 'draw') {
            if (editors[imageIndex]?.canvas?._objects?.length > 0) {
                //This will take the object out of the canvas and push it to the array    
                setCanvasHistory([...canvasHistory, editors[imageIndex].canvas._objects.pop()])
                editors[imageIndex].canvas.renderAll()
            }
        } 
    };





    const redo = () => {
        if (canvasHistory.length > 0 && !!editors[imageIndex]?.canvas) {
            //This will take the object out of the array and put it in the canvas  
            const newHistory = [...canvasHistory]
            editors[imageIndex]?.canvas.add(newHistory.pop())
            setCanvasHistory([...newHistory])
            editors[imageIndex]?.canvas.renderAll()
        }
    };

    const onBrushSizeChange = (size) => {
        setBrushSize(size)
    } 

    const onBrushColorChange = (color) => {
        setBrushColor(color)
    } 



    const save = () => {
        // save images
        // console.log("Save button clicked - imageData", imageData)

        let dataToSave = []

        const saveToServer = async () => {
            const SaveImageServer = `${process.env.REACT_APP_SERVER}/api/v1/image_edits/${patientCtx.pat_token}?dict_company_code_id=${companyCtx.company.id}`;

            await fetch(SaveImageServer, {
                method: "POST",
                body: JSON.stringify({
                  images: dataToSave,
                  note: note,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                  auth: authCtx.authMode,
                },
            })
            //If there is an error, the dialog will show up, it's called outside of this component because of forward refs
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Unprocessable Entity: ' + res.statusText);
                }
                return res.json();
            })
            .then((data) => {
                console.log(data); 
                if (data.detail === 'success' && data.status_code === 1) {
                    // success - close the window
                    setImages([])
                    // to update list of visits
                    props.onSave()
                    // to close this dialog
                    props.hide()
                } 
            })
            .catch((err) => {
                console.log(err.message);
            });
        }


        const saveImages = async () => {
            for (let i = 0; i < imageCount; i++) {
                dataToSave.push(savePageData(i))
            }
            saveToServer()
        };
        saveImages()


    }




    // hide all but the showIndex canvas
    const showHide = (showIndex) => {
        for(let i=0; i < imageCount; i++) {
            let div = document.getElementById(`ImageEditFabric_${i}`)
            if (!!div) {
                if (i === showIndex) {
                    div.style.position = 'relative'
                    div.style.top = '0px'
                } else {
                    div.style.position = 'absolute'
                    div.style.top = '-5000px'
                }
               
            }
        }
        
        
    }



    const savePageData = (pageIndex) => {
        let editor = editors[pageIndex]
        // console.log("SavePageData with scale - page, multiplier", pageIndex, 1/editor.canvas.getZoom())
        if (!!editor) {
            const image_data = {
                image_json_data: JSON.stringify(editor.canvas.toJSON()),
                file_name: props.images[imageIndex].file_name,
                image_edit_base64: editor.canvas.toDataURL({
                    format:"image/png",
                    multiplier: 1/editor.canvas.getZoom()
                }),
                page_number: pageIndex + 1,
            }
            // console.log("savePageData image_data", image_data)
            return image_data
        }
        return null
    }

    const selectPrev = () => {
        let newIndex = imageIndex-1
        if (newIndex < 0) {
            newIndex = imageCount-1
        }
        setImageIndex(newIndex);
        // show newIndex image, hide the rest
        showHide(newIndex)
        setZoomScale(zoomScales[newIndex])

        setMode('draw')
    }


    const selectNext = () => {
        let newIndex = imageIndex+1
        if (newIndex >=imageCount) {
            newIndex = 0
        }
        setImageIndex(newIndex);
        // show newIndex image, hide the rest
        showHide(newIndex)
        setZoomScale(zoomScales[newIndex])
        setMode('draw')
    }



    const changeDrawingMode = (new_mode) => {
        // if changing from select mode - deselect everything
        if (mode === 'select') {
            editors[imageIndex].canvas.discardActiveObject().renderAll();
        }
        setMode(new_mode)
    }


    // position cursor to the end of the text
    const onFocus = (e) => {
        const pos = e.target.value.length
        e.target.setSelectionRange(pos, pos);
    }

    const onZoomScaleChange = useCallback((new_scale, index) => {
        // console.log("Zoom Scale changed - index, scale", index, new_scale)
        let zoom_scales = [...zoomScales]
        zoom_scales[index] = new_scale
        setZoomScales([...zoom_scales])
        if (index === imageIndex) {
            setZoomScale(new_scale)
        }
        
    },[zoomScales])


    const onImageLoaded = useCallback((image_index) => {
        if (image_index === props.images.length - 1) {
            showHide(0)
        }
    },[])


    useEffect(() => {
        const dims = innerDimensions(refBody.current)
        if (bodySize.width !== dims.width && bodySize.height !== dims.height) {
            console.log("Body size", innerDimensions(refBody.current) )
            setBodySize(innerDimensions(refBody.current))
        }
        
    },[])


    useEffect(() => {
        let allImages = props.images.map((img) =>  {return {image: img}})

        const getEditImage = async (image_index, id) => {
            const editImageURL = `${process.env.REACT_APP_SERVER}/api/v1/image_edits/${id}`
            const headers = { Authorization: `Bearer ${userPrefCtx.bearerToken}`, auth: authCtx.authMode,};
    
            try {
                await fetch(editImageURL, { headers })
                .then((res) => res.json())
                .then((data) => {
                    if (!!data && data.length > 0 && !!data[0]?.ImageEditJson?.image_json_data) {
                        allImages[image_index] = {...allImages[image_index], ImageEditJson: data[0].ImageEditJson,  }
                        return data[0].ImageEditJson.image_json_data
                    }
                })
                .catch(e => {
                    console.log("error getting image ", e);
                    return e
                })
            } catch (e) {
                console.log(e);
                return e
            }
        }

        let promises = []
        for(let i = 0; i < props.images.length; i++) {
            if (!!props.images[i].image_edit_json_id) {
                // image that was edited before - get json data
                promises.push(getEditImage(i, props.images[i].image_edit_json_id))
            }
        }
        Promise.all(promises).then(() => {
            setImages([...allImages])
            // console.log("All Images are loaded into array", allImages)
            setImageCount(props.images.length)
        
        })

    }, [props.images])


    return (
        <IconContext.Provider value={{ className: "shared-class", size: 18 }}>
        <Modal 
            fullscreen={true}
            show={props.show} onHide={()=>props.hide()} animation={false}
        >
            <Modal.Header closeButton className='py-1 h60'>
                <>
                    {/* <h3 className='mb-0'>Image Editing (Image <span ref={refCurrent}>{imageIndex+1}</span> of <span ref={refCount}>{imageCount}</span>)</h3> */}
                    {/* <Button variant="outline-primary" onClick={selectPrev}>Previous</Button>
                    <Button variant="outline-primary" onClick={selectNext}>Next</Button> */}
                    <div className='d-flex align-items-center edit-image-header'>
                        <div className='d-inline-block d-xl-none'>
                            <h3 className='mb-0'>Img <span ref={refCurrent}>{imageIndex+1}</span>/<span ref={refCount}>{imageCount}</span></h3>
                        </div>
                        <div className='d-xl-inline d-none'>
                            <h3 className='mb-0'>Image Editing (Image <span ref={refCurrent}>{imageIndex+1}</span> of <span ref={refCount}>{imageCount}</span>)</h3>
                        </div>
                        
                        <EditImageToolbarHorizontal 
                            // brushColor={brushColors[imageIndex]}
                            brushColor={brushColor}
                            // brushSize={brushSizes[imageIndex]}
                            brushSize={brushSize}
                            onBrushSizeChange={(size) => onBrushSizeChange(size)} 
                            onBrushColorChange={(color) => onBrushColorChange(color)} 
                            // mode={modes[imageIndex]} 
                            mode={mode} 
                            onModeChange={(mode) => changeDrawingMode(mode)}
                            selectPrev={selectPrev}
                            selectNext={selectNext}
                            prevNextDisabled={imageCount < 2}
                            undo={undo}
                            redo={redo}
                            onDeleteSelected={onDeleteSelected}
                            // scale={zoomScales[imageIndex]}
                            scale={zoomScale}
                            disableControls={showNotes || showColorPicker}
                            onShowHideColorpicker={showHideColorpicker}
                            showColorPicker={showColorPicker}
                        />

                        <div className='d-inline-block d-xxl-none'>
                            <Button 
                                className='ml-1 btn-sm' 
                                variant="outline-primary" 
                                disabled={showColorPicker}
                                onClick={showHideNotes}>
                                    {showNotes?<FaRegEyeSlash/>:<FaRegEye/>}<MdNotes/>
                            </Button>
                        </div>

                        <div className='d-xxl-inline d-none'>
                            <Button 
                                className='ml-1 btn-md' 
                                variant="outline-primary" 
                                disabled={showColorPicker}
                                onClick={showHideNotes}>
                                    {showNotes? 'Hide':'Show'} Notes
                            </Button>
                        </div>
                    </div>
                </>
            </Modal.Header>
            <Modal.Body className='d-flex xxx-align-items-center justify-content-center' ref={refBody}>
                <div className="d-flex flex-column xxx-justify-content-center">
                <div className='image_overlay' ref={refOverlay}></div>
                    {showNotes &&
                    <>
                        
                        <div className='d-block mb-4 notes p-3 note-frame'>
                            <Row>
                                <Col xs={11}><h4>Enter note (it will auto-save)</h4></Col>
                                <Col className='text-end'><span className='close-button' onClick={showHideNotes}>X</span></Col>
                            </Row>
                            
                            <Form.Control as="textarea" rows={5} onChange={noteChanged} defaultValue={note} autoFocus={true} onFocus={onFocus}/>
                        </div>
                    </>

                    }

                    {bodySize.width > 0 && images.length > 0 && imageCount === images.length &&
                        images.map((img, imgix) => {
                            return (
                                <ImageEditFabric 
                                    key={imgix}
                                    setPageEditor={setPageEditor}
                                    // brushColor={brushColors[imgix]}
                                    // brushSize={brushSizes[imgix]}
                                    brushColor={brushColor}
                                    brushSize={brushSize}
                                    image={img}
                                    // setZoomScale={setZoomScales[imgix]}
                                    setZoomScale={onZoomScaleChange}
                                    // bodySize={innerDimensions(refBody.current)}
                                    bodySize={bodyDimentions}
                                    image_index={imgix}
                                    onImageLoaded={onImageLoaded}
                                    // mode={modes[imgix]}
                                    mode={mode}
                                    // deleteSelectedChosed={deleteTimes[imgix]}
                                    // undoChosed={undoTimes[imgix]}
                                    // redoChosed={redoTimes[imgix]}
                                    // saveImageData={(data)=>saveImageData(data, imgix)}
                                    // imageData={imageData[imgix]}

                                />
                            )
                        })
                    
                    }

                    

                        
                        


                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowConfirm(true)}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => save()}>
                    Save
                </Button>

            </Modal.Footer>
        </Modal>


        <ConfirmDialog 
            show={showConfirm}
            iconColor='orange'
            icon={<BsFillExclamationTriangleFill />}
            title='Please confirm'
            modal_class='delete-confirm-modal'
            title_class='delete-confirm-title'
            footer_class='delete-confirm-title'
            prompt={confirmDialogText}
            prompt_class='delete-confirm-prompt'
            confirm_button_variant='danger'
            cancel_button_text='No! Go back!'
            confirm_button_text='Yes, close'
            cancel_button_variant='primary'
            onClose={(result) => closeConfirmDialog(result)}
        />
        </IconContext.Provider>
    )

}

export default EditImages
