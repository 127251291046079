import React, { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form'
import { FormGroup, FormLabel, Row, Col } from 'react-bootstrap'
import userPrefContext from '../../../context/userPrefContext';
import DictionaryContext from '../../../context/dictionaryContext';
import AuthContext from '../../../context/authContext';
// import VisitWithPrefContext from '../../../context/visitWithPrefContext';
import '../../stylesheets/BotoxMap.css'


const BotoxMapImage2 = (props) => {
    const userPrefCtx = useContext(userPrefContext);
    const dictCtx = useContext(DictionaryContext);
    const authCtx = useContext(AuthContext)

    let defaultVals = userPrefCtx.dictBotoxMapSrcImage?.id

    const onSelect = async (e) => {
        if(!!e.target.value) {
            // setImage(e.target.value)
            props.sendBotoxImageToParent(e.target.value)
      
            const dictBotoxMapSrcImgURL = `${process.env.REACT_APP_SERVER}/api/v1/user_preferences/dict_botox_map_src_image_id`;
      
            const response = await fetch(dictBotoxMapSrcImgURL, {
                    method: "PATCH",
                    body: JSON.stringify({
                    "dict_botox_map_src_image_id": e.target.value
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userPrefCtx.bearerToken}`,
                    auth: authCtx.authMode,
                },
            })
            .then((res) => res.json())
            .then((data) => {
                //console.log('returning: ', data);
                //This is to trigger the visit useEffect after the patch call is made
                props.visitTrigger(data);
            })
            .catch((err) => {
                console.log(err.message);
            })
        } else {
            console.log('e.target.value is blank')
        }
    }


    return (
    <>
        <FormGroup as={Row} className="align-items-center">
            <FormLabel column sm={5} /*className='col-form-label col-sm-4 col-xs-6 my-0'*/>Botox Map Image:</FormLabel>

            {/* <div className='col-sm-6 my-1'> */}
            <Col sm={6} lg={6}>
                <Form.Select
                    name='botox_type_dropdown' 
                    size='sm'
                    onChange={onSelect}
                    defaultValue={userPrefCtx.dictBotoxMapSrcImage?.id}
                    id="botox-image-select"
                    // defaultValue={defaultVals}
                >
                    <option value={0}>botox-map-full.png</option>
                    {dictCtx.dict_botox_map_src_image.map(mi => 
                    <option 
                        key={mi.id}
                        value={mi.id}
                        data-key="data"
                    >
                        {mi.orig_file_name}
                    </option>
                    )}
                </Form.Select>
            </Col>
            {/* </div> */}
            

        </FormGroup>
    </>
    )

}
export default BotoxMapImage2;