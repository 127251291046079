import React, {useState, useRef, useEffect} from 'react'
import {Col, Row, Button} from 'react-bootstrap'
import './BrushesHorizontal.css'
import {FaRedo, FaUndo, FaArrowLeft, FaArrowRight, FaPencilAlt} from 'react-icons/fa'
import { FaPlus, FaMinus } from "react-icons/fa6";
import {Form} from 'react-bootstrap'
import { IconContext } from 'react-icons';

function BrushesHorizontal(props) {

    // console.log("Brushes - props.brushSize", props.brushSize)

    const [iconStyle, setIconStyle] = useState({color: 'black', size: 20})

    const [size, setSize] = useState(0)
    const [showColorPicker, setShowColorPicker] = useState(false)
    const refBrush = useRef()
    const refBrushOutline = useRef()
    const refSlider = useRef()
    const refColor = useRef()
    const refColorPicker = useRef()
    const refSelectedBrushSize = useRef()
    const refIcon = useRef()
    const refLabelSize = useRef()
    const refBrushThickness = useRef()


    // colors ordered by columns (3 rows)
    const colors = [
        '#4D4D4D',
        '#333333',
        '#000000', 

        '#999999',
        '#808080',
        '#666666',

        '#FFFFFF',
        '#CCCCCC',
        '#B3B3B3',

        '#F44E3B',
        '#D33115',
        '#9F0500',

        '#FE9200',
        '#E27300',
        '#C45100',

        '#FCDC00',
        '#FCC400',
        '#FB9E00',

        '#DBDF00',
        '#B0BC00',
        '#808900',

        '#A4DD00',
        '#68BC00',
        '#194D33',

        '#68CCCA',
        '#16A5A5',
        '#0C797D',

        '#73D8FF',
        '#009CE0',
        '#0062B1',

        '#AEA1FF',
        '#7B64FF',
        '#653294',

        '#FDA1FF',
        '#FA28FF',
        '#AB149E',
    ]

    // reorder colors by rows - 12 colors in a row
    let colorRows = colors.map(color => null)
    for(let clrix = 0; clrix < colors.length; clrix++) {
        colorRows[clrix%3*12 + Math.floor(clrix/3)] = colors[clrix]
    }


    // convert rgb to hex and back are taking from here:
    // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb

    const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }).join('')

      
    const hexToRgb = hex =>
        hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                   ,(m, r, g, b) => '#' + r + r + g + g + b + b)
          .substring(1).match(/.{2}/g)
          .map(x => parseInt(x, 16))


    const getBrightness = (r,g,b) => 0.2*r + 0.7*g + 0.1*b

    const calculateBackgroundColor = (hexColor) => {

        // console.log("hex color", hexColor)

        const black = '#000000'
        const white = '#ffffff'
        const lightgray = '#4D4D4D'
        const darkgray = '#a0a0a0'
        const gamma = 2.2; //2.2;
        // convert hex color to r,g,b
        let rgb = hexToRgb(hexColor)

        // console.log("RGB", rgb)

        const r = rgb[0]
        const g = rgb[1]
        const b = rgb[2]

        // console.log("brightness", getBrightness(r,g,b))

        const brightness = getBrightness(r,g,b)

        // let luminocity = Math.sqrt(0.2126 * (r**gamma) + 0.7152 * (g**gamma) + 0.0722 * (b** gamma));

        // console.log("luminocity 1", luminocity)

        // luminocity = Math.sqrt( 0.299*r**gamma + 0.587*g**gamma + 0.114*b**gamma )
        // console.log("luminocity 2", luminocity)
        // console.log("0.5**gamma", 0.5**gamma)
        // if (luminocity > (0.5**gamma)) {
        //     return black
        // } else {
        //     return white
        // }

        if (brightness < 200) return white
        // if (brightness < 128) return lightgray
        // if (brightness < 192) return darkgray
        return black

    }
    
    // const refLabel = useRef()


    // let brushSizeChoices = []
    // for(let i=1; i <= 50; i++) {
    //     brushSizeChoices.push({
    //         label: i,
    //         // value: iv.display_name_short,
    //         value: i,
    //     })
    // }



    const colorSelected = (newColor) => {
        // color selected
        props.onColorChanged(newColor)
        // change color of the slider
        // refSlider.current.style.accentColor=newColor;
        // refBrush.current.style.background=newColor;
        refSelectedBrushSize.current.style.background=newColor;


        setIconStyle({...iconStyle, color: newColor})

        // change backgrounbd color - on;y of enabled

        refIcon.current.style.background=calculateBackgroundColor(newColor);

        
        // hide color picker
        // setShowColorPicker(false)
    }


    const changeBrushSize = (num) => {
        setSize(num)
        props.setBrushSize(num)
        // refBrush.current.style.width=`${num * props.scale}px`
        // refBrush.current.style.height=`${num * props.scale}px`

        // brush outline circle (clickable area) - normal size 52px (to fit max brush size = 50 + 1px wide border)
        // refBrushOutline.current.style.width=`${50 * props.scale + 2}px`
        // refBrushOutline.current.style.height=`${50 * props.scale + 2}px`
    }

    // const onBrushSizeMinus = () => {
    //     if (size > 2) {
    //         let num = size - 1
    //         changeBrushSize(num)
    //     }
    // }

    // const onBrushSizePlus = () => {
    //     if (size < 50) {
    //         let num = size + 1
    //         changeBrushSize(num)
    //     }
    // }


    const colorPickerClicked = (e) => {
        // console.log("Clickled!")
        if (!props.disabled) {
            setShowColorPicker(true)
            props.onShowHideColorpicker(true)
            // document.getElementById("color").focus();
            // document.getElementById("color").click();
        }
    }

    const closeColorPicker = () => {
        setShowColorPicker(false)
        props.onShowHideColorpicker(false)
    }

    const onColorChanged = (event) => {
        // console.log("Color selected (e.target): ", e.target, e.target.value)
        props.onColorChanged(event.target.value)
        // change color of the slider
        // refSlider.current.style.accentColor=event.target.value;
        // refBrush.current.style.background=event.target.value;
    }

    // const sliderChanged = (e) => {
    //     // IMPORTANT! convert size (returned as a string) to a number; otherwize the drawing shifts after mouse up!
    //     let num = +e.target.value

    //     changeBrushSize(num)

    //     // setSize(num)
    //     // props.setBrushSize(num)
    //     // refBrush.current.style.width=`${num * props.scale}px`
    //     // refBrush.current.style.height=`${num * props.scale}px`

    //     // // brush outline circle (clickable area) - normal size 52px (to fit max brush size = 50 + 1px wide border)
    //     // refBrushOutline.current.style.width=`${50 * props.scale + 2}px`
    //     // refBrushOutline.current.style.height=`${50 * props.scale + 2}px`
    // }



    const brushThickness = (bsize, scale) => {
        const adjustedSize = `${bsize*scale}px`
        return (
            <div className={`brush-thickness ${bsize === props.brushSize ? 'current-size':''}`} onClick={()=>changeBrushSize(bsize)}>
                {/* <div className={`line-x line-${bsize}`}></div><span>{bsize} px</span> */}
                <div className={`line-x`} style={{height:adjustedSize}}></div><span>{bsize} px</span>
            </div>
        )
    }

    // copied from here https://stackoverflow.com/questions/1573053/javascript-function-to-convert-color-names-to-hex-codes/47355187#47355187
    const standardize_color = (str) => {
        var ctx = document.createElement("canvas").getContext("2d");
        ctx.fillStyle = str;
        return ctx.fillStyle;
    }

    const standardize_color_rgb = (str) => {
        var ctx = document.createElement("canvas").getContext("2d");
        ctx.fillStyle = str;
        return hexToRgb(ctx.fillStyle);
    }

    useEffect(() => {
        // console.log("Brushes - size", props.brushSize)
        // console.log("Brushes - color", props.brushColor)
        // refSlider.current.value = props.brushSize
        // refSlider.current.style.accentColor=props.brushColor;
        // refBrush.current.style.background=props.brushColor;



        // props.setBrushSize(size)
        // console.log("Brush Size scale", props.scale)
        // refBrush.current.style.width=`${props.brushSize * props.scale}px`
        // refBrush.current.style.height=`${props.brushSize * props.scale}px`

        // brush outline circle (clickable area)
        // refBrushOutline.current.style.width=`${50 * props.scale + 2}px`
        // refBrushOutline.current.style.height=`${50 * props.scale + 2}px`

        setSize(props.brushSize)

        // process props.disabled (apply/remove semi-transparency)
        // let background = refIcon.current.style.background
        // let iconColor = iconStyle.color
        // if (!iconColor.startsWith('rgb')) {
        //     // convert to rgb
        //     const rgb = standardize_color_rgb(iconColor)
        //     iconColor=`rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
        // }

        // console.log("Icon Color before change", iconColor)
        if (props.disabled) {
            refIcon.current.classList.remove('fa-pencil')
            refIcon.current.classList.add('fa-pencil-disabled')
            refLabelSize.current.classList.remove('label-size')
            refLabelSize.current.classList.add('label-size-disabled')
            refBrushThickness.current.classList.remove('brush-thickness')
            refBrushThickness.current.classList.add('brush-thickness-disabled')
            
            // change to rgba with a = 0.5
            // background = background.replace(/^rgb/,'rgba').replace(/\)/,', 0.5)')
            // iconColor = iconColor.replace(/^rgb/,'rgba').replace(/\)/,', 0.5)')
            // console.log("new background", background)
            // refIcon.current.style.background = background
            // setIconStyle({...iconStyle, color: iconColor})

        } else {
            refIcon.current.classList.add('fa-pencil')
            refIcon.current.classList.remove('fa-pencil-disabled')
            refLabelSize.current.classList.add('label-size')
            refLabelSize.current.classList.remove('label-size-disabled')
            refBrushThickness.current.classList.add('brush-thickness')
            refBrushThickness.current.classList.remove('brush-thickness-disabled')
            
            // refBrush.current.style.background = 'none'
            // background = background.replace(/^rgba/,'rgb').replace(/,\s?0.5\)/,')')
            // iconColor = iconColor.replace(/^rgba/,'rgb').replace(/,\s?0.5\)/,')')
            // console.log("new background", background)
            // refIcon.current.style.background = background
            // setIconStyle({...iconStyle, color: iconColor})
        }

        // console.log("Icon Color after change", iconColor)
    }, [props.brushSize, props.brushColor, props.scale, props.disabled])


    useEffect(() => {
        // console.log("showColorPicker", showColorPicker)
        // console.log("props.showColorPicker", props.showColorPicker)
        if (showColorPicker !== props.showColorPicker) {
            setShowColorPicker(props.showColorPicker)
        }
        
    },[props.showColorPicker])


    return (
        <>
        <IconContext.Provider value={iconStyle} >
            {/* <div className='d-flex align-items-center justify-content-center mx-3'> */}

                {/* <div ref={refBrushOutline} className='brush-outline mx-3 d-flex align-items-center justify-content-center' onClick={colorPickerClicked}>
                    <div className='brush-size'
                        ref={refBrush} 
                        style={{background: props.brushColor}}
                    >

                    </div>
                </div> */}


                {/* <input className='color' type="color" id="color" name="color" ref={refColor}  onChange={onColorChanged} /> */}

                {/* <div className='d-inline-block d-xxl-none slider-small'>

                    <Button className='ml-1 btn-sm' variant="outline-primary" onClick={onBrushSizeMinus} disabled={props.disabled}><FaMinus/></Button>
                    <label className='mx-3 label-size'>{size}</label>
                    <Button className='ml-1 btn-sm' variant="outline-primary" onClick={onBrushSizePlus} disabled={props.disabled}><FaPlus/></Button> */}


                    {/* <input 
                            ref={refSlider} 
                            id='slider-small' 
                            type='range' 
                            orient='horizontal' 
                            min='1' 
                            max='50' 
                            onChange={e => sliderChanged(e)} 
                            defaultValue={props.brushSize}
                            list='tickmarks'
                            disabled={props.disabled}
                        />

                        <datalist id="tickmarks">
                            <option value="1" label='1'></option>
                            <option value="10" label='10'></option>
                            <option value="20" label='20'></option>
                            <option value="30" label='30'></option>
                            <option value="40" label='40'></option>
                            <option value="50" label='50'></option>
                        </datalist> */}




                {/* </div> */}

                {/* <div className='d-xxl-inline d-none slider-large'>
                    <input 
                        ref={refSlider} 
                        id='slider' 
                        type='range' 
                        orient='horizontal' 
                        min='1' 
                        max='50' 
                        onChange={e => sliderChanged(e)} 
                        defaultValue={props.brushSize}
                        list='tickmarks'
                        disabled={props.disabled}
                    />

                    <datalist id="tickmarks">
                        <option value="1" label='1'></option>
                        <option value="10" label='10'></option>
                        <option value="20" label='20'></option>
                        <option value="30" label='30'></option>
                        <option value="40" label='40'></option>
                        <option value="50" label='50'></option>
                    </datalist>


                </div> */}

                {/* <label className='d-xxl-inline d-none mx-3 label-size'>{size}</label> */}
                
            {/* </div> */}


           
            <div className='d-flex align-items-center justify-content-center mx-3'>
                <div className='fa-pencil' onClick={colorPickerClicked} ref={refIcon}>
                    <FaPencilAlt ></FaPencilAlt>
                </div>
            </div>

            <div className='brush-thickness' onClick={()=>colorPickerClicked()} ref={refBrushThickness}>
                <div 
                    className='brush-line'
                    style={{height:size*props.scale, background: props.brushColor, outline: props.brushColor === '#FFFFFF' ? 'solid black 1px':''}} 
                    ref={refSelectedBrushSize}></div>
            </div>
            <label className='mx-3 label-size' onClick={colorPickerClicked} ref={refLabelSize}>{size}px</label>

            <div className='image_overlay' ref={refColorPicker}>
                {showColorPicker &&
                    <>
                        <div className='d-block mb-4 colors p-3 colors-frame'>
                            <Row className='color-picker-header'>
                                <Col className='col-11'><h4>Select Color</h4></Col>
                                <Col className='text-end' role="button" onClick={closeColorPicker}>X</Col>
                            </Row>
                            
                            {[0,1,2].map(rownum => 
                                 
                                <div key={rownum} className='color-row'>
                                    {colorRows.map((color, clrix) => {
                                        if (clrix%3 === rownum) {
                                             return <div key={clrix} 
                                                className={`color ${color === props.brushColor ? 'current-color':''}`}
                                                style={{background: color}}
                                                onClick={()=> colorSelected(color)}
                                                ></div>
                                        }
                                        
                                    })}
                                </div>
                                
                            )}

                            <Row className='color-picker-header'>
                                <Col className='col-11'><h4>Select Brush Size</h4></Col>
                                {/* <Col className='text-end' role="button" onClick={()=> setShowColorPicker(false)}>X</Col> */}
                            </Row>

                            {Array.from({ length: 10 }, (value, index) => index).map((rownum) => {
                                return (
                                    <Row key={rownum}>{
                                    Array.from({ length: 5 }, (value, index) => rownum*5+index+1).map((value,index) => {
                                        return (<Col key={index}>{brushThickness(rownum*5+index+1, props.scale)}</Col>)
                                    })}
                                    </Row>
                                )
                            })}

 



                        </div>
                    </>

                }

            </div>
        </IconContext.Provider>
        </>
    )
}

export default BrushesHorizontal
