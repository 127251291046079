import React, {useState} from 'react'
// import ColorsHorizontal from './ColorsHorizontal'
import BrushesHorizontal from './BrushesHorizontal'
import {Button, Form} from 'react-bootstrap'
import './EditImageToolbarHorizontal.css'
import {FaRedo, FaUndo, FaArrowLeft, FaArrowRight, FaTrashAlt} from 'react-icons/fa'




function EditImageToolbarHorizontal(props) {

    // console.log("Toolbar color, size", props.brushColor, props.brushSize)

    const onColorChanged = (color) => {
        // setBrushColor(color)
        props.onBrushColorChange(color)
    }

    const onBrushSizeChange = (size) => {
        props.onBrushSizeChange(size)
        
    }

    // const modeChanged = (e) => {
    //     const val = e.target.value
    //     // console.log("radio value", val)
    //     props.onModeChange(val)
    // }


    const switchModeChanged = (e) => {
        const val = e.target.checked
        props.onModeChange(val ? 'draw' : 'select')
    }


    const switchLabel = (mode) => {

            return (<><span className={`draw-${mode} label`}>Draw</span>/<span className={`select-${mode} label`}>Select</span></>)

    }

    return (
        <div className="d-flex align-items-center mx-5">
            <div className='d-inline-block d-xxl-none'>
                <Button className='ml-1 btn-sm' variant="outline-primary" onClick={props.selectPrev} disabled={props.prevNextDisabled}><FaArrowLeft/></Button>
                <Button className='mx-1 btn-sm' variant="outline-primary" onClick={props.selectNext} disabled={props.prevNextDisabled}><FaArrowRight/></Button>
            </div>

            <div className='d-xxl-inline d-none'>
                <Button className='ml-1 btn-md' variant="outline-primary" onClick={props.selectPrev} disabled={props.prevNextDisabled}>Previous</Button>
                <Button className='mx-1 btn-md' variant="outline-primary" onClick={props.selectNext} disabled={props.prevNextDisabled}>Next</Button>
            </div>

            <div className='mx-4'>

                <Form.Check 
                    className='form-check form-check-inline mx-3'
                    type='switch'
                    id='mode-switch'
                    checked={props.mode==='draw'} 
                    label={switchLabel(props.mode)}
                    onChange={e => switchModeChanged(e)} 
                    disabled={props.disableControls}

                />

                <div className='d-inline d-xxl-none'>
                    <Button 
                            className="btn-sm" 
                            variant="outline-primary"
                            onClick={() => props.onDeleteSelected()} 
                            disabled={props.mode==='draw' || props.disableControls} >
                        <FaTrashAlt/>
                    </Button>
                </div>

                <div className='d-xxl-inline d-none'>
                    <Button 
                            className=" btn-md" 
                            variant="outline-primary"
                            onClick={() => props.onDeleteSelected()} 
                            disabled={props.mode==='draw' || props.disableControls} >
                        Delete Selected
                    </Button>
                </div>
            </div>

            <div className='d-inline d-xxl-none mx-4'>
                <Button 
                    className='mx-1 btn-sm' 
                    variant="outline-primary" 
                    onClick={props.undo} 
                    disabled={props.mode !=='draw' || props.disableControls} ><FaUndo/></Button>
                <Button 
                    className='mr-1 btn-sm' 
                    variant="outline-primary" 
                    onClick={props.redo} 
                    disabled={props.mode !=='draw' || props.disableControls} ><FaRedo/></Button>
            </div>

            <div className='d-xxl-inline d-none mx-4'>
                <Button 
                    className='mx-1 btn-md' 
                    variant="outline-primary" 
                    onClick={props.undo} 
                    disabled={props.mode !=='draw' || props.disableControls} >Undo</Button>
                <Button 
                    className='mr-1 btn-md' 
                    variant="outline-primary" 
                    onClick={props.redo} 
                    disabled={props.mode !=='draw' || props.disableControls} >Redo</Button>
            </div>


            <BrushesHorizontal 
                brushSize={props.brushSize} 
                setBrushSize={onBrushSizeChange} 
                brushColor={props.brushColor}

                onColorChanged={color => onColorChanged(color)}
                scale={props.scale}
                disabled={props.disableControls}
                onShowHideColorpicker={props.onShowHideColorpicker}
                showColorPicker={props.showColorPicker}
                
            />
        </div>
    )
}

export default EditImageToolbarHorizontal
