import React, {useState, useContext, useRef, useEffect, forwardRef, useImperativeHandle, memo} from 'react'
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from 'fabric';
import CompanyContext from '../../../context/companyContext';
import PatientContext from '../../../context/patientContext';
// import UserPrefContext from '../../../context/userPrefContext';
// import AuthContext from '../../../context/authContext'
import './ImageEditFabric.css'



const ImageEditFabric = memo( (props) => {
    const companyCtx = useContext(CompanyContext)
    const patientCtx = useContext(PatientContext)
    // const userPrefCtx = useContext(UserPrefContext)
    // const authCtx = useContext(AuthContext)

    const { editor, onReady } = useFabricJSEditor();
    // const [mode, setMode] = useState('draw')
    // const [canvasHistory, setCanvasHistory] = useState([]);
    // const [canvasData, setCanvasData] = useState(null)



    let scale = 1.0
    scale = 0.0

    // console.log("ImageEditFabric - enter", props)


   
    const calculateScale = (canvas) => {
        let canvasW = props.bodySize.width
        let canvasH = props.bodySize.height

        const img = canvas.backgroundImage;
        // console.log("Background image", img)
        if (!!img) {
            const img_ratio = (img.width/img.height)
            if (canvasW/canvasH > img_ratio) {
                canvasW = canvasH * img_ratio
            } else {
                canvasH = canvasW/img_ratio
            }
    
            const scaleY = canvasH / img.height
            const scaleX = canvasW / img.width
            scale = Math.min(scaleY, scaleX, 1.0)
            // console.log("scale", scale)
    
            // change size of the canvas
            canvasW = img.width * scale
            canvasH = img.height * scale


            if (canvas.getWidth() !== canvasW) {
                canvas.setWidth(canvasW)
            }
            if (canvas.getHeight() !== canvasH) {
                canvas.setHeight(canvasH)
            }
            if (canvas.getZoom() !== scale) {
                canvas.setZoom(scale)
                props.setZoomScale(scale, props.image_index)
            }
            
    
            
            // canvas.renderAll();
        } else {
            scale = 1.0
            canvas.setZoom(scale)
            props.setZoomScale(scale, props.image_index)
        }
        

    }


    const _onReady = (canvas, image, index) => {
            // console.log("On Ready - image, index", image, index)

            if (props.bodySize.width === 0) 
                return

            if (!!image.ImageEditJson) {
                canvas.loadFromJSON(image.ImageEditJson.image_json_data, () => {
                    // console.log("loaded image from json")
                    // canvas.renderAll();
                })
            }

            let which_api = 'file_uploads'
            if (!!image.ImageEditJson) {
                which_api = 'image_edits'      
            }

            // default URL - for regular images
            const imageURL = `${process.env.REACT_APP_SERVER}/api/v1/${which_api}/image/normal_${image.image.file_name}?patient_id=${patientCtx.pat_id}&dict_company_code_id=${companyCtx.company.id}`
            // console.log("loading image from URL", imageURL)
            fabric.Image.fromURL(imageURL, img => {
                let canvasW = props.bodySize.width
                let canvasH = props.bodySize.height

                // console.log("Canvas size", canvasW, canvasH)
                // console.log("Image size", img.width, img.height)

                // calculate image sizes ratio
                const img_ratio = (img.width/img.height)

                // console.log("Image ratio (width/height)", img_ratio)

                if (canvasW/canvasH > img_ratio) {
                    canvasW = canvasH * img_ratio
                } else {
                    canvasH = canvasW/img_ratio
                }

                // console.log("Adjusted canvas size", canvasW, canvasH)

                const scaleY = canvasH / img.height
                const scaleX = canvasW / img.width
                scale = Math.min(scaleY, scaleX, 1.0)
                // console.log("scale", scale)

                // change size of the canvas
                canvasW = img.width * scale
                canvasH = img.height * scale
                canvas.setWidth(canvasW)
                canvas.setHeight(canvasH)

                // console.log("Adjusted canvas size final", canvasW, canvasH)

                // don't set up background picture if loaded from Json (if should be already there)
                if(!!!image.ImageEditJson) {
                    canvas.setBackgroundImage(img);
                }
                

                // setActiveImage(img)

                canvas.setZoom(scale)

                props.setZoomScale(scale, props.image_index)

                // console.log("positioning image: bodySize width, canvasW, left", props.bodySize.width, canvasW, (props.bodySize.width - canvasW) / 2)

                canvas.renderAll();

                // tell parent that the image has been loaded
                props.onImageLoaded(props.image_index)


                
            },{crossOrigin: "Anonymous"})
            

            

        onReady(canvas);

        
    }



    useEffect(() => {
        // console.log("useEffect - editor", editor)
        // console.log("Use Effect - editor, canvasData", editor, canvasData)
        if(!!editor) {
            
            editor.canvas.freeDrawingBrush = new fabric.PencilBrush(editor.canvas)
            let brush = editor.canvas.freeDrawingBrush
            // brush.limitedToCanvasSize = true
            brush.color = props.brushColor;
            brush.width = (+props.brushSize);

            // console.log("setting up brush - size & color", brushSize, brushColor)
            editor.canvas.isDrawingMode = props.mode === 'draw' ? true : false

            props.setPageEditor(editor, props.image_index)
        }
    }, [editor])


    useEffect(() => {
        if (!!editor) {
            editor.canvas.isDrawingMode = props.mode === 'draw' ? true : false

            // setMode()
        }
    }, [props.mode])


    // useEffect(() => {
    //     if (!!editor) {
    //         editor.canvas.freeDrawingBrush = new fabric.PencilBrush(editor.canvas)
    //         let brush = editor.canvas.freeDrawingBrush
    //         // brush.limitedToCanvasSize = true
    //         brush.color = props.brushColor;
    //         brush.width = (+props.brushSize);
    //     }
    // }, [props.brushSize, props.brushColor])


    useEffect(() => {
        // console.log("Use Effect - body size", props.bodySize)
        // recalculate scale
        if (props.bodySize.width !== 0 && !!editor && scale === 0.0) {
            calculateScale(editor.canvas)
        }
    }, [props.bodySize])


    return (
        <div className='canvas-border border border-dark' 
            id={`ImageEditFabric_${props.image_index}`}
            // ref={refCanvas}
        >
            <FabricJSCanvas 
                onReady={(canvas) => _onReady(canvas, props.image, props.image_index)} 
                backgroundImage={props.image}  
                brushColor={props.brushColor}
                brushSize={props.brushSize}
            />
        </div>
    )
})

export default ImageEditFabric
